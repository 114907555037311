// Font weights
$regular:400;
$bold: 700;
$extra_bold: 800;

// Base Font
$base-font-family: 'Biryani', sans-serif;
$base-font-weight: $regular;
$base-font-size: 16px;
$base-line-height: 1.6;

//base colors
$color-primary: #196cb4;
$color-primary-light: #17BEEB;
$color-secondary: #F39C12;
$color-white: #ffffff;
$color-gray: #a7a7a7;
$color-gray-light: #f1f3f1;
//link colors
$color-link: $color-secondary;
$color-link-dark: darken($color-secondary, 10);
$color-link-light: $color-white;

//text colors
$color-text-gray: #404040;
$color-text: #004A78;
$color-text-light: rgba(0, 74, 120, 0.6);
$color-text-lightest: $color-white;