/*--------------------------------------------------------------
# places 
--------------------------------------------------------------*/

.news {
    width: 100%;
    float: left;
    padding: 100px 0px 125px 0px;
    background: lighten($color-gray-light, 3);

    .pager {
        width: 100%;
        float: left;
        margin-top: 50px;

        .nav-previous {
            float: right;
        }
    }

    &.single {
        .image {
            height: 300px;
        }
    }

    &.overview {
        padding-top: 200px;
    }

    p.center {
    	margin-bottom: 50px;
    }

    .image {
        height: 140px;
        width: 100%;
        float: left;
    }

    .button {
        width: 200px;
        left: 50%;
        margin-left: -100px;
        float: left;
        position: relative;
        margin-top: 40px;
    }

    .item {
    	border:1px solid $color-gray-light;
    	box-sizing:border-box;
    	position: relative;
        background: #fff;
        float: left;
        margin-top: 25px;
        width: 100%;
        transition:all 0.3s ease;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

        a.btn_back{
            width: 100%;
            font-weight: bold;
            margin-top: 30px;
            border-top: 1px solid #eee;
            float: left;
            padding-top: 10px;
        }

        .inner {
            padding: 20px;
            width: 100%;
            float: left;

            h3 {
                line-height: 30px;
                margin-bottom: 0px;
                width: 100%;
                float: left;
            }

            .date {
                color: rgba(0, 0, 0, 0.6);
                margin-bottom: 10px;
                width: 100%;
                float: left;
            }
        }
    }

    a.item:hover {
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    }
}


/*--------------------------------------------------------------
# Responsive reviews style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {
}

@include breakpoint(sm) {
	.news {
        &.overview {
            padding-top: 60px;
        }

		padding-top: 30px;
	}
}
