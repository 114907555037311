/*--------------------------------------------------------------
# Header style
--------------------------------------------------------------*/

.load {
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 1000;
}

.margin-topbar {
    margin-top: 25px;
}

.topbar {
    width: 100%;
    float: left;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 500;
    background: rgba(255, 255, 255, 0);
    opacity: 1;
    &.subpage {
        background: rgba(255, 255, 255, 1);
    }
    &.nav_fade-in,
    &.subpage {
        color: $color-text;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        .logo {
            background: url(../images/baggage-wbs.png) center center no-repeat;
            background-size: 100% auto;
        }
        .nav li {
            color: $color-text;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            &:hover a,
            &:hover {
                opacity: 1;
                color: $color-primary-light;
            }
            a {
                color: $color-text;
            }
            span.menu {
                color: $color-text;
                &:hover {
                    opacity: 1;
                    color: $color-primary-light;
                    .fa {
                        background: $color-primary-light;
                    }
                }
                .fa {
                    background: $color-text;
                    color: #fff;
                }
            }
        }
    }
    .logo {
        margin: 10px 0px 10px 0px;
        width: 420px;
        float: left;
        height: 60px;
        background: url(../images/baggage-wbs-wit.png) center center no-repeat;
        background-size: 100% auto;
        transition: all 0.3s ease;
    }
    .nav {
        float: right;
        list-style: none;
        margin-top: 21px;
        transition: all 0.3s ease;
        li {
            float: left;
            font-size: 16px;
            font-weight: $bold;
            color: $color-white;
            line-height: 30px;
            border-right: 1px solid rgba(255, 255, 255, 0.3);
            &:hover a,
            &:hover {
                background: none;
                opacity: 0.8;
            }
            .fa.fa-search {
                font-size: 21px;
                margin: 2px 10px 0px 0px;
                float: left;
            }
            &.last {
                border-right: 0px;
            }

            &.lang a{
                padding:5px 0px 5px 20px;
            }
            .fa.fa-bars {
                width: 25px;
                height: 25px;
                background: $color-white;
                color: $color-primary;
                text-align: center;
                border-radius: 25px;
                line-height: 25px;
                margin-right: 5px;
                transition: 0.5s ease all;
            }
            a,
            span.menu {
                margin: 0px;
                padding: 0px;
                display: block;
                color: $color-white;
                padding: 5px 20px;
                cursor: pointer;
                transition: 0.5s ease all;
            }
            span.menu:hover .fa {
                background: $color-primary;
                color: $color-white;
            }
        }
    }
}
.topbar{
    opacity: 1!important;
}

.navbar{
    background-color: white;
    @media(max-width: 990px){
        display: flex;
        text-align-last: center;
    }
    .container{
        @media(max-width: 768px){
            width: 100%;
        }
        .mobile-menu-toggle{
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
            p{
                margin-top: 15px;
                margin-right: 10px;
                font-size: 20px;
            }
            i{
                margin-top: 0;
                font-size: 15px;
                margin-bottom: 1px;
                margin-left: 5px;
            }
        }

        #menu-navbar, #menu-navbar-en{

            @media(max-width: 990px){
                display: none;
            }
            padding: 0;
            height: 50px;
            @media(max-width: 990px){
                height: auto;
                flex-direction: column;
                justify-content: center;
            }
            align-items: center;
            margin-bottom: 0px;
            @media(max-width: 990px){
                li{
                    padding-top: 1em;   
                    padding-bottom: 1em;   
                }
               
            }
            @media(max-width: 768px){
                padding-left: 0;
            }
            a{
                @media(max-width: 1200px){
                    font-size: 14px;
                }
                @media(max-width: 990px){
                    font-weight: bold;
                    font-size: 18px;
                }               
            }
            @media(max-width: 990px){
                li{
                    display: flex;
                    justify-content: center;
                }
            }
            .menu-item-has-children{
                position: relative;
                height: 100%;
                @media(max-width: 990px){
                    padding-bottom: 1em;
                    padding-top: 1em;
                    width: 250px;
                }
                a{
                    color: #1384B4;
                    z-index: 2;

                }
                i{
                    font-size: 10px;
                    position: absolute;
                    right: 15px;
                    z-index: 1;
                    transition: .5s ease;
                    transform: translateY(-2px);
                    @media(max-width: 990px){
                        top: 20px;
                    }
                }
            }
            .menu-item{
                display: inline-flex;
                align-items: center;
                width: fit-content;
                @media(max-width: 990px){
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    width: 100%;
                    border-bottom: 1px solid #f5f5f5;
                }
                a{
                    display: flex;
                    width: 100%;
                    padding-right: 2em;
                    padding-left: 1em;
                    height: 100%;
                    align-items: center;
                    @media(max-width: 990px){
                        justify-content: start;
                    }
                }
                .sub-menu{
                    display: none;
                    box-shadow: 0 10px 10px #0000005c;
                    position: absolute;
                    padding: 0;
                    background-color: #fff;
                    top: 50px;
                    left: -10px;
                    width: 250px;
                    @media(max-width: 990px){
                        display: none;
                        padding: 0;
                        font-size: 12px;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        box-shadow: revert;
                        width: 100%;
                        padding-left: 25px;
                        z-index: 0;
                        top: 0;
                        left: 0;
                        li{
                            margin-right: 0px;
                            height: auto!important;
                            @media(max-width: 990px){
                                border-bottom: 0px!important;
                            }
                        }
                        a{
                            font-size: 14px;
                            font-weight: normal;
                        }
                    }
                    li{
                        font-size: 14px;
                        margin-right: 0px!important;
                        height: 50px;
                        min-width: 100%;
                        @media(max-width: 990px){
                            height: auto;
                        }                        
                        &:hover{
                            background-color: #F5F5F5;
                            @media(max-width: 990px){
                                background-color: white;
                            }
                        }
                    }
                }
                .sub-menu-open{
                    display: block!important; 
                }
            }
        }
        .mobile-menu-toggle{
            display: none;
            @media(max-width: 990px){
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
            color: black;
            font-size: 2em;
            span{
                padding-top: 5px;
                margin: 10px 0;
                font-size: 15px;
            }
        }
        .internal-sub-trigger{
            position: relative;
            margin-right: 0px!important;
            @media(max-width: 990px){
                width: 100%;
                border-bottom: 0px!important;
            }
        }
        .internal-sub-menu{
            position: absolute;
            display: none;
            top: 0;
            left: 250px;
            background-color: #fff;
            padding: 0;
            @media(max-width: 990px){
                position: initial;
                width: 100%;
                padding-left: 25px;
            }
            li{
                min-width: 250px!important;
                display: flex;
                justify-content: center;
                border: 1px solid grey;
                @media(max-width: 990px){
                    border: none;
                }
            }
        }
    }
}

.menu_overlay {
    background: rgba(15, 52, 85, 0.6);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    z-index: 1000;
    display: none;
    nav {
        width: 600px;
        float: right;
        background: #0f3455;
        min-height: 100%;
        position: relative;
        box-sizing: border-box;
        padding: 30px 30px 80px 30px;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
        .close {
            position: absolute;
            left: -40px;
            top: 15px;
            font-size: 40px;
            color: $color-white;
            cursor: pointer;
            width: 25px;
            height: 25px;
            background: url(../images/close.png) center center no-repeat;
            background-size: 100%;
            &:hover {
                opacity: 0.5;
            }
        }
        ul {
            list-style: none;
            width: 100%;
            box-sizing: border-box;
            float: left;
            padding: 0px;
            &.big {
                li {
                    font-size: 30px;
                    font-weight: $extra_bold;
                    line-height: 60px;
                }
            }
            &.small {
                padding-top: 30px;
                border-top: 1px solid rgba(255, 255, 255, 0.15);
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                padding-bottom: 20px;
                li {
                    font-size: 18px;
                    line-height: 36px;
                    &.marginTop {
                        margin-top: 20px;
                    }
                }
            }
            a {
                color: #fff;
                &:hover {
                    opacity: 0.5;
                }
            }
        }
        .button {
            margin-top: 25px;
            text-align: center;
        }
    }
}


/*--------------------------------------------------------------
# Responsive header style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {
    .topbar .logo {
        width: 295px;
    }
    .menu_overlay {
        nav {
            width: 400px;
        }
    }
}

@include breakpoint(sm) {
    .topbar {
        .logo {
            height: 34px;
            width: 180px;
        }
        .nav {
            margin-top:13px;
            
            li {
                a {
                    padding: 5px 15px;
                }

                &.lang a {
                    padding: 5px 0px 5px 15px;
                }

                span.menu {
                    padding: 5px 5px;
                }

                span.text {
                    display: none;
                }
            }
            .lost-found {
                display: none;
            }
        }
    }
    .menu_overlay {
        nav {
            width: 100%;
            .close {
                left: auto;
                right: 20px;
            }
            ul {
                &.big {
                    margin-top: 20px;
                }
            }
        }
    }
}
