/*--------------------------------------------------------------
# Checkout
--------------------------------------------------------------*/
.payment_page {
	width: 100%;
	float: left;
	padding-bottom: 100px;

	.wc_payment_method.payment_method_PayLater {
		display: none !important;
	}

	.checkout_box {
		width: 100%;
		float: left;
		background:$color-gray-light;
		padding: 20px 40px 40px;
		box-sizing:border-box;

		.totals {
			width: 100%;
			float:left;

			.total {
				width: 100%;
				float: left;
				padding-bottom: 10px;
				margin-top: 10px;
				border-bottom: 1px solid darken($color-gray-light, 30);

				.product-total {
					width: 60%;
					float: left;
				}

				.name {
					width: 40%;
					float: left;
					font-weight: $bold;
				}
			}
		}

		#payment {
			width: 100%;
			float: left;
			background:none;

			.wc_payment_methods {
				img {
					float: left;
					width: 23px;
					margin: 3px 10px 0px 0px;
				}

				.input-radio {
					margin-right: 5px;
				}

				.payment_box.payment_method_mollie_wc_gateway_ideal {
					strong {
						display: none;
					}

					br {
						display: none;
					}
				}

				.payment_box.payment_method_mollie_wc_gateway_mistercash,.payment_box.payment_method_mollie_wc_gateway_paypal, .payment_box.payment_method_mollie_wc_gateway_sofort, .payment_box.payment_method_mollie_wc_gateway_creditcard {
					display: none !important;
				}
			}

			.form-row {
				padding: 0px;
				margin: 0px;

				.button {
					width: 100%;
				    padding: 5px 15px;
				    height: auto;
				    font-size: 18px;
				    margin-top:20px;
				    background:$color-secondary;
				    cursor: pointer;
				    text-align: center;

				    &:hover, &:active, &:focus {
				    	color:$color-white;
				    	background:lighten($color-secondary, 10);
				    }

				}
			}
		}
	}

	.shop_table {
		width: 100%;
		float:left;

		.header {
			padding: 0px 0px 10px 0px;
			width: 100%;
			margin-bottom: 0px;
			font-size:18px;
			border-bottom: 1px solid $color-gray-light;
			color:$color-primary;
			float: left;
			box-sizing: border-box;
			font-weight: $bold;

			.product-name {
				width: 80%;
				float:left;
			}

			.product-total {
				width: 20%;
				float: left;
			}
		}

		.body {
			width: 100%;
			float: left;
			box-shadow: 0 0 5px rgba(0,0,0,.05);
			border: 1px solid #f1f3f1;
			padding: 20px;
			box-sizing: border-box;

			.order_item {
				width: 100%;
				float:left;
				padding: 0px 0px 20px 0px;
				box-sizing:border-box;
				margin-top:20px;
				border-bottom: 1px solid $color-gray-light;

				dt {
					width: 100%;
					float: left;
					margin-top:10px;
				}

				dd {
					float: left;
					width: 100%;
					padding-bottom: 5px;
					color: darken($color-gray, 15);

					p {
						color: darken($color-gray, 10);
						font-size: 14px;
						margin-bottom: 0px;
					}
				}

				.variation-Prijs, .variation-Price, .variation-Afstandbestemming-Afleverlocatie, .variation-Distancedestination-DeliveryLocation {
					display: none !important;
				}
				
				.product-name {
					width: 80%;
					float:left;
				}

				.product-subtotal {
					width: 20%;
					float: left;
					font-weight: $bold;
					color:$color-gray;
				}

				.name {
					font-size: 16px;
					color:$color-primary;
					font-weight: $bold;
				}
			}
		}
	}
}