/*--------------------------------------------------------------
# Footer style
--------------------------------------------------------------*/
footer.bottom {
	width: 100%;
	float: left;
	padding:0px 0px 0px 0px;
	color:$color-text;
		
	.usp-bar {
		margin-bottom: 50px;
		color: $color-text-gray;
		border-top:1px solid darken($color-gray-light, 5);
	}

	.payment-footer {
		width: 100%;
		float: left;
		margin-left: -5px;
		margin-top: 10px;

		img {
			float: left;
			max-height: 22px;
			margin: 2px;
		}
	}

	h4 {
		width: 100%;
		float:left;
		font-weight: $bold;
		font-size:16px;
	}

	ul {
		list-style: none;
		width: 100%;
		float:left;
		padding:0px;
		margin:0px 0px 20px 0px;

		li {
			width: 100%;
			float:left;
			line-height: 32px;

			.fa {
				float: left;
				margin-right: 5px;
				margin-top: 6px;
				opacity: 0.9;
			}

			a {
				color:$color-text;
				opacity: 0.8;
				font-size: 14px;

				&:hover {
					opacity: 1;
				}
			}
		}
	}

	.contact {
		font-size: 14px;

		.marginTop {
			margin-top:15px;
		}

		a {
			width: 100%;
			float:left;
			color:$color-text;
		}
	}

	.copy {
		width: 100%;
		opacity: 0.9;
		padding:20px 0px;
		float:left;
		font-size: 14px;
		margin-top:30px;

		a {
			color:$color-text;
		}

		.db {
			text-align: right;

			span {
				font-weight: bold;
			}
		}
	}
}

.modal-koerier-big {
	width: 100%;
	height: 100%;
	position: fixed;
	background: rgba(0, 0, 0, 0.8);
	left: 0px;
	top: 0px;
	z-index: 99999;
	display: none;

	.inner {
		background: #fff;
		padding: 30px;
		width: 500px;
		left: 50%;
		margin: 50px 0px 50px -250px;
		box-sizing: border-box;
		position: relative;
		border-radius: 3px;
		box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);

		.close {
			position: absolute;
			right: 10px;
			top: 10px;
			cursor: pointer;
			font-size: 24px;

			&:hover {
				opacity: 0.8;
			}
		}


		@include breakpoint(sm) {
			width: 98%;
			margin: 10px 1%;
			left: 0px;
			min-height: 100vh;
		}
	}
}

/*--------------------------------------------------------------
# Responsive footer style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {
footer {
	&.bottom {
		padding: 50px 0 0;
		.copy {
			margin-top: 35px;
			padding-left: 15px;
			.db {
				text-align: left;
			}
		}
		.contact {
			float: left;
		}
	}
}
}

@include breakpoint(sm) {

}