/*--------------------------------------------------------------
# Page style
--------------------------------------------------------------*/
.bg_img {
	height: 750px;
	width: 100%;
	float: left;
	background: url(../images/globalblue-bg.jpg) center bottom no-repeat;
	background-size: cover;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}

.reset {
	width: 100%;
	float: left;
	text-align: center;
	font-weight: bold;
	margin: -20px 0px 20px 0px;
	color: rgba(0, 0, 0, 0.8);

	&:hover {
		color: rgba(0, 0, 0, 1);
	}
}

.terms {
	list-style: none;
	padding: 0px;
	float: right;

	li {
		float: left;
		font-size: 14px;

		a {
			text-decoration: underline;
			padding: 0px 10px;
		}
	}
}

.page-template-template-global, .page-template-template-global-kooijman, .thankyou-page-global, .page-template-template-global-mediamarkt, .page-template-template-global-bijenkorf-rotterdam {
	.content_page {
		padding-top: 0px;
	}
}

.thankyou-page-global {
	.topbar, footer.bottom {
		display: none !important;
	}
}

@media print {
	.woocommerce-order-received {
		.topbar, .bg_img, .thankyou, .steps, .bottom, .print_btn{
			display: none !important;
		}

		.shipment_info {
			position: fixed;
			top: 0px !important;
			border: 0px !important;
			padding: 0px !important;
		}

		.label {
			border: 0px !important;
		}

		iframe {
			display: none !important;
		}

		.global_calc {
			box-shadow: 0px !important;
			padding: 0px !important;
		}
	}

	.globalblue_v2 .pager .back {
		display: none;
	}
}

.payment_method_cheque {
	display: none !important;
}

.global-thankyou {
	.globalblue_v2 {
		.global_calc {
			margin-top: 0px;
		}

		.bg_img {
			display: none;
		}

		.shipment_info {
			width: 100%;
			padding: 40px;
			border: 1px solid #eee;
			float: left;
			margin-top: 20px;

			.print_btn {
				margin: 50px 0px 0px -80px;
				padding: 8px 20px;
				background: $color-secondary;
				float: left;
				color: #fff;
				font-weight: bold;
				position: relative;
				cursor: pointer;
				left: 50%;

				&:hover {
					background: lighten($color-secondary, 5);
				}
			}

			.left, .right {
				float: left;
				width: 50%;
			}

			.field {
				width: 100%;
				float: left;
				margin-bottom: 10px;

				.label {
					width: 30%;
					float: left;
					font-size: 14px;
					font-weight: bold;
					line-height: 18px;
					color: $color-primary;
				}

				.value {
					width: 70%;
					font-size: 14px;
					line-height: 18px;
					float: left;
				}
			}
		}

		.thankyou {
			width: 100%;
			float: left;
			padding-top: 75px;
			text-align: center;

			h3 {
				font-size: 21px;
			}

			h2 {
				font-weight: bold;
				color: $color-primary;
			}

			.next_steps {
				width: 100%;
				float: left;
				background: #f9f5f1;
				padding: 30px;
				margin-top: 50px;

				h3 {
					font-size: 18px;
					color: rgba(0, 0, 0, 0.8);
				}

				h2 {
					font-size: 24px;
					color: rgba(0, 0, 0, 0.8);
				}

				.check {
					display: inline-block;
					margin: 20px 0px 0px 10px;

					.fa {
						color:#60a60e;
					}
				}
			}
		}
	}
}
.globalblue_v2 {
	width: 100%;
	float: left;
	position: relative;
	
	.payment_method_pin {
		&.active {
			.fa-check {
				display: inline-block;
			}
		}

		label {
			color: $color-primary;
			font-weight: bold;
			cursor: pointer;
			text-decoration: underline;

			.fa-check {
				color: #57b612;
				display: none;
			}
		}
	}

	.woocommerce-error {
		float: left;
		width: 100%;
		margin-top: 20px !important;
		margin-bottom: -20px !important;
	}

	.loader_overlay {
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 1000;
		background: rgba(255, 255, 255, 0.9);
		display: none;

		.lds-ellipsis {
			display: block;
			top: 50%;
			margin-top: -20px;
		}
	}

	.basic-error {
		display: none;
	}

	.step_box {
		width: 100%;
		float: left;
		display: none;

		&.step1 {
			display: block;
		}
	}

	.bg_img {
		height: 400px;
		width: 100%;
		float: left;
		background: url(../images/globalblue-bg.jpg) center bottom no-repeat;
		background-size: cover;
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 1;
	}

	.container {
		position: relative;
		z-index: 10;
	}

	.global_calc {
		width: 100%;
		margin-bottom: 50px;
		background: #fff;
		padding: 25px 35px;
		box-sizing: border-box;
		float: left;
		box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
		margin-top: 50px;
	}

	.top-bar {
		float: left;
		left: 50%;
		position: relative;
		-webkit-transform: translate3d(-50%, 0px, 0px);
		   -moz-transform: translate3d(-50%, 0px, 0px);
		    -ms-transform: translate3d(-50%, 0px, 0px);
		     -o-transform: translate3d(-50%, 0px, 0px);
		        transform: translate3d(-50%, 0px, 0px);

		.logo_global {
			width: 300px;
			height: 50px;
			float: left;
			background: #fff url(../images/global-blue.jpg) center center no-repeat;
			background-size: auto 40px;
			box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
			margin-top: -45px;
		}

		.logo_company {
			width: 300px;
			height: 50px;
			float: left;
			margin-left: 25px;
			box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
			margin-top: -45px;	
		}
	}

	.steps {
		width: 100%;
		float: left;
		position: relative;
		margin-top: 20px;

		.line {
			position: absolute;
			bottom: 9px;
			left: 0px;
			margin-left: 10%;
			width: 80%;
			float: left;
			height: 7px;
			background: $color-primary;

			.inner {
				height: 7px;
				background: $color-secondary;
				float: left;
				padding: 0px !important;
			}
		}

		.step {
			width: 20%;
			float: left;
			cursor: pointer;

			&:hover {
				span {
					opacity: 0.8;
				}
			}

			span {
				width: 100%;
				float: left;
				color: darken($color-gray, 25);
				text-align: center;
				font-size: 14px;
			}

			.circle {
				box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
				border: 3px solid $color-primary;
				width: 26px;
				height: 26px;
				border-radius: 30px;
				float: left;
				left: 50%;
				background:#fff;
				margin-left: -13px;
				position: relative;
			}

			&.active {
				.circle {
					background: $color-primary;
				}

				span {
					color: $color-primary;
					font-weight: bold;
				}
			}

			&.done {
				.circle {
					border: 3px solid $color-secondary;
					background: $color-secondary;
				}

				span {
					opacity: 0.5;
					color: $color-primary;
					font-weight: bold;
				}	
			}
		}
	}

	.left, .right {
		width: 50%;
		float: left;
		border-right: 1px solid #eee;
		padding: 0px 20px 0px 20px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.left {
		padding-left: 0px;
	}

	.right {
		padding-right: 0px;
	}

	.right {
		border-right: 0px;
	}
	
	.boxes {
		width: 100%;

		.box {
			width: 50%;
			float: left;
			border-bottom: 1px solid #eee;
			margin-bottom: 15px;
			padding-bottom: 15px;

			.title {
				font-size: 13px;
				font-weight: bold;
				width: 100%;
				float: left;
				line-height: 16px;
				margin-bottom: 0px;
			}

			.sub_title {
				font-size: 11px;
				width: 100%;
				line-height: 13px;
				float: left;
				margin-bottom: 5px;
			}

			figure {
				width: 40%;
				float: left;
				padding: 0px;
				margin: 0px;
				text-align: center;

				img {
					width: 60%;
					height: auto;
				}
			}

			.amount {
				width: 60%;
				float: left;
				margin-top: 5px;

				input {
					width: 45px;
					float: left;
					padding: 5px 0px 5px 5px;
					border-radius: 10px;
					float: left;
					margin: 0px 10px;
					border: 1px solid darken(#eee, 10);

					&:focus {
						outline: none;
						border: 1px solid $color-primary;
					}
				}

				.control {
					background: $color-primary;
					color: #fff;
					margin-top: 3px;
					width: 20px;
					height: 20px;
					border-radius: 20px;
					float: left;
					margin-top: 8px;
					font-size: 14px;
					line-height: 26px;
					text-align: center;
					cursor: pointer;

					&:hover {
						background: $color-secondary;
					}
				}
			}
		}
	}

	.other-sizes {
		width: 100%;
		float: left;
		margin-top: 20px;
		.box-sizes {
			width: 50%;
			border: 1px solid #eee;
			padding: 15px;
			float: left;

			.field {
				width: 25%;
				float: left;
				padding: 5px;

				.label {
					font-weight: bold;
					font-size: 11px;
				}

				input {
					width: 100%;
					float: left;
					padding: 5px 0px 5px 5px;
					border-radius: 10px;
					float: left;
					margin: 0px;
					border: 1px solid darken(#eee, 10);

					&:focus {
						outline: none;
						border: 1px solid $color-primary;
					}
				}
			}

			.info {
				font-size: 12px;
				color: rgba(0, 0, 0, 0.7);
			}

			.btnAddBox {
				width: 100%;
				font-weight: 700;
				color: #fff;
				line-height: 44px;
				background: $color-secondary;
				box-shadow: inset -2px -2px 3px 0 rgba(245,169,47,.9);
				transition: all .4s ease;
				padding: 0 15px;
				border-radius: 3px;
				text-align: center;
				position: relative;
				cursor: pointer;

				&:hover {
					background: lighten($color-secondary, 5);
				}
			}
		}

		.custom_boxes {
			width: 50%;
			padding-left: 25px;
			box-sizing: border-box;
			float: left;

			.box {
				width: 100%;
				position: relative;
				padding-bottom: 5px;
				margin-bottom: 5px;

				.remove {
					position: absolute;
					right: 0px;
					top: 0px;
					cursor: pointer;
					z-index: 100;

					&:hover {
						opacity: 0.5;
					}
				}

				span {
					font-size: 13px;
					opacity: 0.5;
					line-height: 16px;
					float: left;
					margin-top: 13px;
				}

				.title {
					font-size: 16px;
					margin-bottom: -10px;
				}
			}
		}
	}

	.price_box {
		width: 100%;
		float: left;
		padding: 10px;
		box-shadow: 1px 2px 1px rgbA(0, 0, 0, 0.1);

		p {
			font-size: 14px;
		}

		.products {
			width: 100%;
			float: left;
			display: none;
		}

		.line {
			width: 100%;
			float: left;
			padding: 10px 0px;
			border-bottom: 1px solid #eee;

			.count {
				font-weight: bold;
				float: left;
				min-width: 25px;
			}

			.name {
				float: left;

				i {
					font-style: normal;
					font-size: 12px;
				}
			}

			.price {
				float: right;
			}
		}

		.tax {
			width: 100%;
			float: left;
			padding: 5px 0px;

			span {
				font-size: 14px;
				opacity: 0.8;
			}

			.price {
				float: right;
			}
		}

		.totals {
			width: 100%;
			float: left;
			margin-top: 10px;
			font-weight: bold;
			color: $color-primary;

			.price {
				font-size: 24px;
				float: right;
			}
		}
	}

	.destination, .insurance {
		width: 100%;
		float: left;
		border: 0px;
		margin: 0px 0px 5px 0px;
	}

	.insurance {
		margin-top: 20px;

		.insurance_optional {
			width: 100%;
			float: left;
			line-height: 26px;
			font-size: 14px;
			margin-bottom: 5px;

			input {
				margin-right: 5px;
			}
		}
	}

	h3 {
		font-weight: bold;
		color: $color-primary;
		font-size: 16px;
		margin-top: 0px;
		float: left;
		width: 100%;
		margin-bottom: 5px;
	}

	h4 {
		float: left;
		width: 100%;
		font-weight: bold;
		font-size: 14px;
		margin-top: 0px;
	}

	.pager {
		width: 100%;
		float: left;
		margin-top: 10px;

		.back {
			width: 100%;
			float: left;
			color: #999;
			text-decoration: underline;
			text-align: center;
			margin-top: 20px;
		}

		.prev {
			float: left;
			cursor: pointer;
			background: #eee;
			font-size: 14px;
			color: #999;
			box-shadow: inset -2px -2px 3px 0 rgba(0,0,0,.05);

			&:hover {
				background: darken(#eee, 5);
			}

			.fa {
				float: left;
				margin: 9px 10px 0px 0px;
			}
		}

		.next {
			cursor: pointer;
			float: right;
		}

		button[type="submit"] {
			float: right;
			border-radius: 3px;
			border: 0;
			width: auto;
			height: 40px;
			font-weight: 700;
			color: #fff;
			line-height: 44px;
			background: #f39c12;
			box-shadow: inset -2px -2px 3px 0 rgba(245,169,47,.9);
			transition: all .4s ease;
			padding: 0 15px;
			position: relative;

			.fa {
				margin-left: 10px;
			}

			&:hover {
				background: lighten($color-secondary, 5);
			}
		}
	}

	.button.addFile {
		margin-top: 10px;
		cursor: pointer;
		background: #eee;
		float: right;
		font-size: 14px;
		color: #999;
		box-shadow: inset -2px -2px 3px 0 rgba(0,0,0,.05);

		&:hover {
			background: darken(#eee, 5);
		}
	}

	.documents {
		width: 100%;
		float: left;
		margin-bottom: 30px;

		&.files {
			margin-bottom: 0px;
		}

		.document {
			width: 100%;
			float: left;
			padding: 10px;
			margin-bottom: 10px;
			box-shadow: 0px 0px 2px rgbA(0, 0, 0, 0.1);
			position: relative;

			.remove {
				position: absolute;
				right: 10px;
				top: 10px;
				font-size: 21px;
				cursor: pointer;
				z-index: 100;

				&:hover {
					opacity: 0.8;
				}
			}
		}

		.row_field {
			width: 100%;
			float: left;
			margin-bottom: 10px;
		}
	}

	.row_field {
		position: relative;
		font-size: 14px;
		width: 100%;
		float: left;
		
		label.error {
			position: absolute;
			bottom: -20px;
			background: #fff;
			font-size: 10px;
			left: 0px;
			padding: 0px 5px;
			margin-left: 8px;
		}

		&.terms {
			margin-top: 20px;
			font-size: 14px;
		}

		&.left {
			width: 50%;
			border-right: 0px;
			padding-right: 5px;
			margin-top: 0px;
		}

		&.right {
			margin-top: 0px;
			width: 50%;
			padding-left: 5px;
		}

		.label {
			font-size: 14px;
			font-weight: bold;
		}

		input, textarea {
			width: 100%;
			float: left;
			padding: 10px 0px 5px 10px;
			border-radius: 10px;
			float: left;
			margin: 0px;
			font-weight: 400;
			border: 1px solid darken(#eee, 10);

			&[type="checkbox"] {
				width: auto;
				float: left;
				margin: 4px 5px 0px 0px;
			}

			&.error {
				border: 1px solid #f00;
			}

			&:focus {
				outline: none;
				border: 1px solid $color-primary;
			}
		}

		select {
			width: 100%;
			float: left;
		}
		
		sup {
			color: #f00;
		}

		textarea {
			height: 80px;
		}

		&.with_sign {
			input {
				padding-left: 40px;
			}
			.sign {
				font-weight: bold;
				border-right: 1px solid darken(#eee, 10);
				padding: 10px;
				position: absolute;
				top: 0px;
				height: 43px;
				left: 0px;
			}
		}
	}

	.summery {
		width: 100%;
		float: left;
		padding: 10px;
		font-size: 14px;
		background: #f9f5f1;

		.document_summary_row, .content_summary_row, .package_summary_row, .content_other_row, .destination_summary_row, .value_summary_row {
			display: none;
		}

		.line {
			width: 100%;
			float: left;

			.name {
				font-weight: bold;
				width: 30%;
				float: left;

				i {
					font-style: normal;
					font-size: 12px;
				}
			}

			.value {
				width: 70%;
				float: left;
				opacity: 0.8;
			}
		}
	}

	.fields {
		.row_field {
			margin-bottom: 10px;
		}
	}

	.payment_box {
		width: 100%;
		float: left;

		.tab {
			width: 45%;
			float: left;
			margin-top: 20px;
			padding: 15px 10px;
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			background: #eee;
			color: #000;
			cursor: pointer;
			box-shadow: inset -2px -2px 3px 0 rgba(0,0,0,.05);

			&:hover {
				background: darken(#eee, 2);
			}

			span {
				font-weight: 400;
				width: 100%;
				float: left;
				color: #999;
				font-size: 16px;
			}

			&.active {
				background: darken(#eee, 2);
				padding-bottom: 40px;
			}
		}

		.txt {
			width: 10%;
			float: left;
			line-height: 82px;
			text-align: center;
			font-weight: bold;
			margin-top: 20px;
		}

		.payment_options {
			padding: 20px;
			width: 100%;
			float: left;
			margin-top: -10px;
			background: darken(#eee, 2);

			.payment_method_PayLater {
				display: none;
			}

			&.later {
				display: none;
			}

			ul {
				padding: 0px;

				li {
					font-size: 14px;
					padding: 5px 0px;
					list-style: none;
					width: 100%;
					float: left;
					border-bottom: 1px solid #eee;
					font-weight: bold;

					input[type="radio"] {
						margin-right: 5px;
					}

					img {
						width: 30px;
						float: right;
					}
				}
			}
		}
	}
}

.pay_cash {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	background: rgba(255, 255, 255, 0.9);
	z-index: 1000;
	float: left;
	display: none;

	.verify {
		width: 300px;
		background: #fff;
		box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
		left: 50%;
		position: relative;
		padding: 40px 40px 20px 40px;
		float: left;
		text-align: center;
		box-sizing: border-box;
		margin: 100px 0px 100px -150px;
		
		p {
			font-size: 14px;
			font-weight: bold;
		}

		input {
			border: 1px solid darken(#eee, 5);
			float: left;
			width: 100%;
			text-align: center;
			border-radius: 4px;
			font-size: 60px;
			height: 60px;
			padding: 30px 0px 15px 5px;
			line-height: 40px;

			&:focus {
				outline: none;
				border: 1px solid $color-primary;
			}
		}

		.close_pay {
			float: left;
			width: 100%;
			cursor: pointer;
			margin-top: 10px;
			text-decoration: underline;
		}
	}
}

.total_to_high {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	background: rgba(255, 255, 255, 0.9);
	z-index: 1000;
	float: left;
	display: none;

	.verify {
		width: 300px;
		background: #fff;
		box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
		left: 50%;
		position: relative;
		padding: 20px;
		float: left;
		//text-align: center;
		line-height: 21px;
		box-sizing: border-box;
		margin: 100px 0px 100px -150px;
		
		p {
			font-size: 14px;
		}

		.close_high {
			float: left;
			width: 100%;
			cursor: pointer;
			text-align: center;
			margin-top: 10px;
			text-decoration: underline;
		}
	}
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  left: 50%;
  margin-left: -32px;
  display: none;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.global-bottom {
	width: 100%;
	float: left;
	border-top: 1px solid #eee;
	margin-top: 50px;
	padding: 25px 0px;

	span {
		float: left;
		font-weight: bold;
		font-size: 14px;
		width: 100%;
		text-align: left;
	}

	.logo {
		width: 300px;
		background: url(../images/baggage-wbs.png) 50% no-repeat;
		background-size: 100% auto;
		float: left;
		height: 35px;
		margin-top: -0px;
	}
}

/*--------------------------------------------------------------
# Responsive page style
--------------------------------------------------------------*/
@include breakpoint(lg) {
	.globalblue_v2 .other-sizes .custom_boxes .box .title {
		font-size: 14px;
	}

	.globalblue_v2 .global_calc {
		padding: 20px;
		margin-top: 100px;
	}
}

@include breakpoint(md) {



	.globalblue_v2 .left, .globalblue_v2 .right {
		width: 100%;
		padding: 0px;
		margin-bottom: 0px;
		border: 0px;
	}

	.global-thankyou .globalblue_v2 .shipment_info {
		padding: 0px;
		border: 0px;

		.left, .right {
			width: 100%;
			padding: 0px;
			margin: 20px 0px 0px 0px;
			border: 0px;

			.label {
				width: 100%;
			}

			.value {
				width: 100%;
			}
		}

		.print_btn {
			margin-top: 0px;
		}
	}

	.global-thankyou .globalblue_v2 .global_calc {
		padding: 10px;
	}

	.globalblue_v2 .steps .step span {
		font-size: 11px;
	}
	
	.globalblue_v2 .documents {
		margin-bottom: 0px;
	}

	.globalblue_v2 .top-bar .logo_company {
		margin-top: -50px;
	}
}

@include breakpoint(sm) {
	.globalblue_v2 .other-sizes .box-sizes, .globalblue_v2 .other-sizes .custom_boxes {
		width: 100%;
	}

	.globalblue_v2 .other-sizes .custom_boxes {
		margin-top: 20px;
		padding-left: 0px;
	}
	
	.globalblue_v2 .pager .prev, .globalblue_v2 .pager .next, .globalblue_v2 .pager button[type=submit] {
		width: 100%;
		float: left;
		margin-bottom: 5px;
		text-align: center;
	}
	.globalblue_v2 .global_calc {
		padding: 10px;
	}

	.globalblue_v2 .steps .step span {
		display: none;
	}

	.globalblue_v2 .row_field.left, .globalblue_v2 .row_field.right {
		width: 100%;
		margin: 0px 0px 10px 0px;
		padding: 0px;
	}

	.globalblue_v2 .payment_box .tab span {
		display: none;
	}

	.globalblue_v2 .payment_box .tab {
		font-size: 14px;
	}

	.globalblue_v2 .steps .step .circle {
		width: 15px;
		height: 15px;
		margin-left: -8px;
		margin-top: 0px;
	}

	.globalblue_v2 .steps .line {
		height: 3px;
		bottom: 6px;

		.inner {
			height: 3px;
		}
	}

	.global-thankyou .globalblue_v2 .global_calc {
		margin-top: -80px;
	}

	.globalblue_v2 .top-bar {
		width: 100%;
		float: left;
		position: relative;
	}

	.globalblue_v2 .top-bar .logo_company, .globalblue_v2 .top-bar .logo_global {
		width: 49%;
		height: 50px;
		float: left;
		padding: 10px;
		background-size: auto 25px;
	}

	.global-thankyou .globalblue_v2 .thankyou {
		padding-top: 30px;
	}

	.global-thankyou .globalblue_v2 .thankyou .next_steps {
		margin-top: 20px;
	}

	.globalblue_v2 .top-bar .logo_company {
		float: right;
		background-size: auto 25px !important;
	}

	.global-thankyou .globalblue_v2 .thankyou .next_steps {
		padding: 20px 10px;
	}
}

.personal_global {
	padding-top: 75px;
}
