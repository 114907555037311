/*--------------------------------------------------------------
# Thankyou style
--------------------------------------------------------------*/
.content.thankyou {
	width: 100%;
	float: left;
	padding:0px 0px 200px 0px;

	p.intro {
		width: 100%;
		float: left;
	}

	h1.title {
		margin-bottom: 20px;
	}

	.buttons {
		float:left;
		margin-top:50px;
		left:50%;
		margin-left:-217.5px;
		position: relative;

		.button.trans {
			margin-right:15px;
		}
	}
}

/*--------------------------------------------------------------
# Thankyou style
--------------------------------------------------------------*/
@include breakpoint(lg) {
	.content.thankyou {
		padding:0px !important;

		h1.title {
			margin:0px 0px 0px 0px;
			text-align: center;
		}
	}
}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}