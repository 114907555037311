/*--------------------------------------------------------------
# usp bar 
--------------------------------------------------------------*/

.usp-bar {
    width: 100%;
    float: left;
    padding: 20px 0px;
    text-align: center;
    font-weight: $bold;
    border-bottom: 1px solid darken($color-gray-light, 5);

    a {
        color: $color-text-gray;
    }

    .fa {
        font-size: 24px;
        color:#5db218;
        position: relative;
        margin-top: 0px;
        margin-right:5px;
    }

    span {
        font-weight: bold;
    }
}


/*--------------------------------------------------------------
# Responsive sidemenu style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}
