/*--------------------------------------------------------------
# lost&found style
--------------------------------------------------------------*/
.no_ref {
    margin: 100px 0px;
    padding: 40px;
    background: #f7dcdc;
    color: #690d0d;
    float: left;
    width: 100%;
    text-align: center;
}
.intro_popup {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    z-index: 99999;
    background:rgba(0, 0, 0, 0.8);
    left: 0px;
    top: 0px;

    .inner {
        padding: 30px;
        width: 700px;
        box-sizing: border-box;
        position: relative;
        background: #fff;
        margin: 50px auto;
        float: left;
        left: 50%;
        margin-left: -350px;

        .close {
            .fa.fa-times {
                float: right;
                font-size: 30px;
                position: absolute;
                right: 15px;
                cursor: pointer;
                top: 15px;
            }

            &:hover {
                opacity: 0.5;
            }
        }

        .button {
            font-size: 16px;
            width: 100%;
            text-align: center;
            box-sizing: border-box;
            cursor: pointer;
            display: inline-block;
        }
        
        @media (max-width: 750px){
            padding: 20px;
            width: 98%;
            margin: 10px 1%;
            left: 0px;
        }
    }
}
.add_reference {
    width: 400px;
    margin: 150px auto 100px auto;
    padding: 25px;
    border: 1px solid #eee;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

    h3 {
        margin: 0px 0px 10px 0px;
        font-weight: bold;
        line-height: 26px;
    }

    input {
        width: 100%;
        float: left;
        padding: 10px 15px;
    }

    .button {
        width: 100%;
        float: left;
        text-align: center;
        margin-top: 10px;
    }
}
.lostfound_v2 {
    padding-bottom: 0px;

    .cancel {
        width: 100%;
        text-align: center;
        color: #636363;
        padding: 10px 0px;
        float: left;
        cursor: pointer;

        .fa {
            background: #f00;
            color: #fff;
            padding: 3px 5px;
            border-radius: 20px;
            display: inline-block;
        }

        &:hover {
            opacity: 0.5;
        }
    }
    .lost_found-wrap {
        background: #f1f3f1;
        padding: 50px 0px 100px 0px;
        width: 100%;
        float: left;
    }
    
    .box {
        width: 100%;
        float: left;
        padding: 25px;
        background: #fff;
        margin-top: 20px;
        box-sizing: border-box;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

        .rule {
            width: 100%;
            float: left;
        }

        h4 {
            font-size: 16px;
            font-weight: bold;
        }

        h3 {
            font-size: 18px;
            font-weight: bold;
            margin:0px 0px 5px 0px;
        }

        p {
            font-size: 14px;
            line-height: 21px;
        }

        .field {
            width: 100%;
            float: left;

            &.half {
                width: 50%;
            }

            &.left {
                padding-right: 20px;
                border-right: 1px solid #eee;
            }

            &.right {
                padding-left: 20px;
            }
        }

        input, textarea {
            height: 45px;
            line-height: 45px;
            padding: 0 10px;
            box-sizing: border-box;
            border: 1px solid #e3e7e3;
            transition: all .4s ease;
            font-size: 16px;
            width: 100%;
            border-radius: 3px;

            &.error {
                border: 1px solid #f00;
            }

            &:focus {
                outline: none;
                border: 1px solid darken(#e3e7e3, 10);
            }
        }

        textarea {
            height: 100px;
            padding: 10px;
            line-height: 26px;
        }

        select {
            width: 100%;
        }

        .label {
            width: 100%;
            font-weight: bold;
            color:  $color-primary;
            font-size: 16px;
            float: left;
            margin-bottom: 5px;

            sup {
                color: #f00;
            }

            .tooltip {
                float: right;
                position: relative;
                cursor: pointer;
                margin-right: 15px;
                &:hover:before {
                    background: $color-text;
                    width: 200px;
                    color: $color-white;
                    padding: 10px;
                    bottom: 37px;
                    content: attr(data-text);
                    left: 50%;
                    font-size: 14px;
                    line-height: 21px;
                    margin-left: -100px;
                    position: absolute;
                    z-index: 99;
                    font-weight: $regular;
                    border-radius: 3px;
                    cursor: auto;
                }
                &:hover:after {
                    color: $color-text;
                    bottom: 22px;
                    font: normal normal normal 14px/1 FontAwesome;
                    content: "\f0d7";
                    left: 50%;
                    margin-left: -8px;
                    position: absolute;
                    font-size: 27px;
                    z-index: 99;
                }
            }
        }
    }

    .approximate_values {
        display: none;
    }

    .open_modal {
        color: $color-primary;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
    }

    .content-popup {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        z-index: 1000;
        left: 0px;
        top: 0px;
        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
        display: none;

        .inner {
            width: 400px;
            padding: 25px;
            box-sizing: border-box;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); 
            float: left;
            margin: 100px 0px 100px -200px;
            left: 50%;
            position: relative;
            background: #fff;

            h3 {
                font-size: 18px;
                font-weight: bold;
                margin: 0px;

                .fa {
                    float: right;
                    font-size: 32px;
                    cursor: pointer;
                    margin-top: -5px;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }

            ul {
                width: 100%;
                float: left;
                padding: 0px 0px 0px 20px;
                margin: 10px 0px 0px 0px;
            }
        }
    }

    .shipping_type {
        width: 100%;
        display: none;

        .type {
            float: left;
            width: 50%;
            text-align: center;
            
            &.left {
                padding-right: 10px;
            }

            &.right {
                padding-left: 10px;
            }

            .name {
                font-size: 18px;
                font-weight: bold;
                color: $color-primary;
            }

            .type_inner {
                padding: 10px;
                width: 100%;
                box-sizing: border-box;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); 
                cursor: pointer;
                position: relative;
                transition: all 0.5s ease;

                &:hover {
                    background: #eee;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); 
                }
            }

            &.active .type_inner {
                background: $color-secondary;
                color: #fff;

                .name {
                    color: #fff;
                }

                .check {
                    display: block;
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    background: #fff;
                    left: 10px;
                    top: 10px;
                    line-height: 35px;
                    text-align: center;
                    font-size: 21px;
                    border-radius: 30px;

                    .fa {
                        color: #0fa027;
                    }
                }
            }

            .meta {
                font-weight: bold;
            }

            .check {
                display: none;
            }
        }
    }

    .summary {
        width: 100%;
        float: left;
        background: #fff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); 
        margin-top: 20px;
        padding: 20px;
        box-sizing: border-box;
        display: none;

        h4 {
            font-weight: bold;
            margin: 0px 0px 10px 0px;
        }

        .item {
            width: 100%;
            float: left;
            line-height: 21px;
            padding: 0px 0px 5px 0px;
            margin-bottom: 10px;
            border-bottom: 1px solid #eee;
            display: none;

            .name {
                font-weight: bold;
                font-size: 14px;
                color: $color-primary;
            }

            .value {
                font-size: 14px;
            }
        }
    }

    .btn {
        color: #fff;
        font-weight: bold;
        padding: 15px 30px;
        margin-top: 20px;
        margin-bottom: 50px;
        cursor: pointer;
        border-radius: 0px !important;
        box-shadow: 0px 0px 0px !important;

        &.disable.next {
            background: #d3d3d3;
            opacity: 0.8;
            cursor: not-allowed;

            &:hover {
                background: #d3d3d3;
            }
        }

        &.next {
            background: $color-primary;
            float: right;

            .fa {
                margin-left: 10px;
            }

            &:hover {
                background: darken($color-primary, 5);
            }
        }

        &.prev {
            background: darken(#eee, 5);
            color: darken(#eee, 50);
            float: left;

            .fa {
                margin-right: 10px;
            }

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .shipping_check {
        width: 100%;
        float: left;

        input[type="checkbox"] {
            float: left;
            width: auto;
            margin-right: 10px;
            height: auto;
        }
    }

    .shipping_fields {
        display: none;
        width: 100%;
        float: left;
        margin-top: 20px;
    }

    .contact-details {
        h3 {
            font-weight: bold;
            margin-bottom: 10px;
        }
        .field {
            margin-bottom: 15px;
        }

        .label {
            margin-bottom: 2px;
            font-size: 14px;
        }
    }

    .number {
        background: $color-secondary;
        color: #fff;
        padding: 20px;
        width: 100%;
        float: left;
        box-sizing: border-box;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

        h3 {
            font-weight: bold;
            color: #fff;
            margin: 0px;
            font-size: 21px;
        }

        span {
            font-size: 21px;
            //font-weight: bold;
        }
    }

    .steps {
        width: 100%;
        float: left;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;

        .line {
            position: absolute;
            bottom: 9px;
            left: 0px;
            margin-left: 17%;
            width: 67%;
            float: left;
            height: 7px;
            z-index: 1;
            background: $color-primary;

            .inner {
                height: 7px;
                background: $color-secondary;
                float: left;
                padding: 0px !important;
            }
        }

        .step {
            width: 33.33%;
            float: left;
            cursor: pointer;

            &:hover {
                span {
                    opacity: 0.8;
                }
            }

            span {
                width: 100%;
                float: left;
                color: darken($color-gray, 25);
                text-align: center;
                font-size: 14px;
            }

            .circle {
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
                border: 3px solid $color-primary;
                width: 26px;
                height: 26px;
                border-radius: 30px;
                float: left;
                left: 50%;
                background:#fff;
                margin-left: -13px;
                position: relative;
                z-index: 10;
            }

            &.active {
                .circle {
                    background: $color-primary;
                }

                span {
                    color: $color-primary;
                    font-weight: bold;
                }
            }

            &.done {
                .circle {
                    border: 3px solid $color-secondary;
                    background: $color-secondary;
                }

                span {
                    opacity: 0.5;
                    color: $color-primary;
                    font-weight: bold;
                }   
            }
        }
    }

    .wc_payment_methods {
        width: 100%;
        float: left;
        list-style: none;
        padding: 0px;
        margin: 0px;

        .wc_payment_method {
            position: relative;
            font-size: 14px;

            input {
                width: auto;
                height: auto;
                line-height: 26px;
                margin-right: 5px;
                float: left;
            }

            .tooltip {
                font-size: 18px;
                float: left;
                position: absolute;
                right: 15px;
                top: 9px;
                cursor: pointer;
                &:hover:before {
                    background: $color-text;
                    color: $color-white;
                    padding: 10px;
                    bottom: 37px;
                    width: 200px;
                    content: attr(data-text);
                    left: 50%;
                    font-size: 14px;
                    line-height: 21px;
                    margin-left: -180px;
                    position: absolute;
                    z-index: 99;
                    font-weight: $regular;
                    border-radius: 3px;
                    cursor: auto;
                }
                &:hover:after {
                    color: $color-text;
                    bottom: 22px;
                    font: normal normal normal 14px/1 FontAwesome;
                    content: "\f0d7";
                    left: 50%;
                    margin-left: -8px;
                    position: absolute;
                    font-size: 27px;
                    z-index: 99;
                }
            }
        }

        .payment_box {
            display: none;
        }

        &.payment_method_PayLater {
            display: none !important;
        }

        li {
            width: 100%;
            float: left;
            background: #f6f6f6;
            border:1px solid #e7e7e7;
            margin: 0px 0px 3px 0px;
            padding: 8px;

            label {
                width: calc(100% - 30px);
            }

            img {
                width: 20px;
                float: left;
                margin: 2px 5px;
                max-width: 100%;
            }
        }
    }


    .insurance_check {
        width: 100%;
        float: left;

        input[type="checkbox"] {
            float: left;
            width: auto;
            margin-right: 10px;
            height: auto;
        }
    }

    .agreements {
        .btn {
            margin: 20px 0px 0px 0px;
            width: 100%;
            text-align: center;
        }
    }

    .tab {
        display: none;

        &.first {
            display: block;
        }
    }

    .after_load {
        display: none;
    }

    #place_id {
        display: none;
    }

    .lds-ellipsis_lf {
      display: inline-block;
      position: relative;
      width: 64px;
      height: 64px;
      float: left;
      left: 50%;
      margin: -20px 0px -15px -32px;
    }
    .lds-ellipsis_lf div {
      position: absolute;
      top: 27px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: #000;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis_lf div:nth-child(1) {
      left: 6px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis_lf div:nth-child(2) {
      left: 6px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis_lf div:nth-child(3) {
      left: 26px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis_lf div:nth-child(4) {
      left: 45px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(19px, 0);
      }
    }

    @media (max-width: 767px){
        &.product {
            margin-top: -10px;
            padding: 54px 0px !important;

            .lost_found-wrap {
                padding-top: 10px;
            }

            .steps {
                margin-bottom: 0px;

                .step {
                    span {
                        font-size: 12px;
                    }
                }
            }

            .box {
                padding: 15px;
            }

            .number {
                background: none;
                box-shadow: 0px 0px 0px;
                padding: 10px 0px 0px 0px;
                color: #000;
                border-top: 1px solid #eee;

                h3 {
                    color: #000;
                }
            }

        }   
        @media (max-width: 580px){
            .box .field.half {
                width: 100%;
                padding: 0px;
            }
        }

        @media (max-width: 500px){
            &.product {
                .steps {
                    .step {
                        span {
                            display: none;
                        }
                    }
                }
                .shipping_type .type {
                    width: 100%;
                    margin: 0px 0px 10px 0px;
                    padding: 0px;
                }

                .btn {
                    width: 100%;
                    text-align: center;
                    margin: 5px 0px 5px 0px;
                }
            }
        }     
    }
}

.help_info {
    width: 100%;
    float: left;
    background: #fff;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 0 5px rgba(0,0,0,.1);

    .phone {
        font-size: 13px;
        line-height: 21px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;

        a {
            width: 100%;
            float: left;
            font-size: 21px;
            font-weight: bold;
            line-height: 28px;
        }
    }

    .chat {
        font-size: 13px;
        line-height: 21px;

        .fa {
            color: #337ab7;
            font-size: 16px;
        }
    }
}


.toolpopup {
    float: right;
    position: relative;
    cursor: pointer;
    margin-right: 15px;

    .fa.fa-info-circle {
        font-size: 18px;
        float: left;
    }

    .popup {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        z-index: 1000;
        left: 0px;
        top: 0px;
        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
        display: none;
    }

    .popup-inner {
        width: 400px;
        padding: 25px;
        box-sizing: border-box;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); 
        float: left;
        margin: 100px 0px 100px -200px;
        left: 50%;
        position: relative;
        background: #fff;

        p {
            font-weight: 300;
            color: #000;
            font-size: 13px;
            line-height: 21px;
            margin-bottom: 15px;
        }

        .fa {
            float: right;
            font-size: 32px;
            cursor: pointer;
            margin-top: -15px;
            z-index: 100;
            right: 20px;
            position: absolute;

            &:hover {
                opacity: 0.5;
            }
        }

        ul {
            width: 100%;
            float: left;
            padding: 0px 0px 0px 20px;
            margin: 10px 0px 20px 0px;
            font-weight: 300;
            font-size: 13px;
            color: #000;
        }

        @media (max-width: 500px){
            width: 95%;
            margin: 20px 2.5% 20px 2.5%;
            left: 0px;
        }
    }            
}

.popup.popup-cancel, .popup.popup-change {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    z-index: 1000;
    left: 0px;
    top: 0px;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    display: none;
    
    .popup-inner {
        width: 400px;
        padding: 25px;
        box-sizing: border-box;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); 
        float: left;
        margin: 100px 0px 100px -200px;
        left: 50%;
        position: relative;
        background: #fff;

        .buttons {
            width: 100%;
            float: left;

            .button {
                float: right;
                cursor: pointer;

                &.gray {
                    float: left;
                    background: #7d7d7d;
                    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
                }
            }
        }

        p {
            font-weight: 300;
            color: #000;
            font-size: 21px;
            font-weight: bold;
            line-height: 26px;
            margin-bottom: 20px;
        }

        .fa {
            float: right;
            font-size: 32px;
            cursor: pointer;
            margin-top: -15px;
            z-index: 100;
            right: 20px;
            position: absolute;

            &:hover {
                opacity: 0.5;
            }
        }

        ul {
            width: 100%;
            float: left;
            padding: 0px 0px 0px 20px;
            margin: 10px 0px 0px 0px;
            font-weight: 300;
            font-size: 13px;
            color: #000;
        }

        @media (max-width: 500px){
            width: 95%;
            margin: 20px 2.5% 20px 2.5%;
            left: 0px;
        }
    } 
} 

.woocommerce-order-pay {
    .content_page {
        background: #f1f3f1;
        padding: 25px;

        h1 {
            margin: 20px 0px 5px 0px;
        }

        .checkout_box {
            background: #fff;
            box-sizing: border-box;
            box-shadow: 0 0 5px rgba(0,0,0,.1);

        }

        .summary.pay_page {
            display: block !important;

            .item {
                display: block !important;
            }
        }
    }

    @media (max-width: 750px){
        .content {
            padding-top: 20px;
        }

        .content_page {
            padding: 0px;

            &.marginTop {
                margin-top: 50px;
            }

            h1 {
                font-size: 21px;
            }

            .checkout_box {
                padding: 20px;
                margin-bottom: 20px;
            }
        }
    }
}