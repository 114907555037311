/*--------------------------------------------------------------
# Reviews 
--------------------------------------------------------------*/

.reviews {
    width: 100%;
    float: left;
    background: url(../images/review.jpg) center center no-repeat;
    background-size: cover;
    padding: 100px 0px;
    figure {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        float: right;
    }
    .slide {
        &:focus {
            outline: none;
        }
    }
    .info {
        width: 100%;
        float: left;
        .rating {
            width: 100%;
            float: left;
            margin: 5px 0px 5px 0px;
            .fa.active {
                color: $color-secondary;
            }
        }
        .author {
            width: 100%;
            float: left;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
        }
    }
    .col-md-6 {
        text-align: center;
        color: $color-white;
        .description {
            margin-top: 5px;
            font-size: 32px;
            font-weight: $extra_bold;
            line-height: 40px;
            float: left;
            width: 100%;
        }
    }
}


/*--------------------------------------------------------------
# Responsive reviews style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {
    .reviews {
        padding: 65px 0;
        figure {
            float: none;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@include breakpoint(sm) {
    .reviews {
    	padding: 40px 0;
        figure {
            width: 100px;
            height: 100px;
        }
        .col-md-6 {
            .description {
                margin-top: 5px;
                font-size: 20px;
                font-weight: 800; 
                line-height: 30px;
                float: left;
                width: 100%;
            }
        }
    }
}
