/*--------------------------------------------------------------
# Home style
--------------------------------------------------------------*/

.hero {
    width: 100%;
    float: left;
    height: auto;
    position: relative;
    z-index: 10;
    margin-top:0px;

    .bg-slider {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        left:0px;
        top: 0px;

        .slide, .slick-list, .slick-track {
            width: 100%;
            height: 100%;
            float: left;
        }
    }

    .overlay_bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);
        z-index: 1;
    }

    .hide {
        opacity: 0;
    }
    .intro {
        text-align: center;
        color: $color-white;
        margin-top: 175px;
        position: relative;
        z-index: 10;

        h2 {
            line-height: 67px;
            font-size: 55px;
            font-weight: $extra_bold;
            margin-bottom: 0px;
        }
        p {
            font-size: 18px;
            line-height: 32px;
            color: rgba(255, 255, 255, 0.8);
        }
    }

    .search {
        width: 100%;
        margin-top: 70px;
        padding: 45px 0px;
        z-index: 20;
        position: relative;
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.5);
        color: $color-white;

        p {
            float: left;
            width: 100%;
        }

        .label {
            line-height: 26px;
            color: #fff;
        }

        h3 {
            margin: 0px;
            float: left;
            font-weight: $extra_bold;
        }
        .help {
            float: left;
            margin: 0px 0px 0px 10px;
            color: rgba(255, 255, 255, 0.6);
            border-left: 1px solid rgba(255, 255, 255, 0.6);
            padding-left: 10px;
            &:hover {
                color: $color-white;
                text-decoration: none;
            }
        }
        .fields {
            margin-top: 30px;
            margin-bottom: 0px;
            .field {
                float: left;
                margin-right: 20px;
                position: relative;
                &.last {
                    margin-right: 0px;
                }
            }
            .label {
                float: left;
                padding: 9px 0px;
                margin-right: 10px;
                span {
                    float: left;
                    padding-right: 15px;
                }
                .tooltip {
                    font-size: 18px;
                    float: left;
                    position: relative;
                    cursor: pointer;
                    &:hover:before {
                        background: $color-text;
                        color: $color-white;
                        padding: 10px;
                        bottom: 37px;
                        width: 200px;
                        content: attr(data-text);
                        left: 50%;
                        font-size: 14px;
                        line-height: 21px;
                        margin-left: -100px;
                        position: absolute;
                        z-index: 99;
                        font-weight: $regular;
                        border-radius: 3px;
                        cursor: auto;
                    }
                    &:hover:after {
                        color: $color-text;
                        bottom: 22px;
                        font: normal normal normal 14px/1 FontAwesome;
                        content: "\f0d7";
                        left: 50%;
                        margin-left: -8px;
                        position: absolute;
                        font-size: 27px;
                        z-index: 99;
                    }
                }
            }
            input {
                &.location {
                    width: 100%;
                }
            }

            .location_wrap {
                width: 238px;
                float: left;
                position: relative;
            }

            .fa-map-marker {
                left: 0px;
                top: 6px;
                margin-left: 10px;
                font-size: 26px;
                position: absolute;
            }
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            input {
                height: 40px;
                line-height: 40px;
                padding: 0px 10px;
                box-sizing: border-box;
                background: rgba(255, 255, 255, 0.35);
                border: 1px solid rgba(255, 255, 255, 0.1);
                transition: all 0.4s ease;
                font-size: 16px;
                width: auto;
                border-radius: 3px;
                &.error {
                    border: 1px solid $color-secondary;
                }
                &.valid {
                    border: 1px solid #95cb61;
                }
                &.location {
                    padding-left: 35px;
                }
                &#place_id {
                    display: none;
                }
                &::-webkit-input-placeholder {
                    color: $color-white;
                }
                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: $color-white;
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: $color-white;
                }
                &:-ms-input-placeholder {
                    color: $color-white;
                }
                &:focus {
                    outline: none;
                    background: rgba(255, 255, 255, 0.45);
                }
                &.small {
                    width: 55px;
                }
            }
            button {
                width: 140px;
                margin-right: none;
            }
        }
        .more_packages {
            float: left;
            text-decoration: underline;
            margin-top: 10px;
            margin-right: 5px;
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
        }
    }
    .second_page {
        margin-top: 125px;
        color: $color-white;
        width: 100%;
        left: 0px;

        .back {
            padding-bottom: 0px;
            border-bottom: 1px solid $color-white;
            float: left;
            color: $color-white;
            cursor: pointer;
        }
        .destination {
            font-size: 21px;
            width: 100%;
            float: left;
            margin-top: 10px;
            span {
                font-size: 30px;
                font-weight: $extra_bold;
            }
        }
        .help {
            width: 100%;
            float: left;
            padding: 20px 0px;
            z-index: 20;
            position: relative;
            .fa {
                font-size: 21px;
                margin: 1px 10px 0px 0px;
                float: left;
            }
            a {
                color: $color-white;
                text-decoration: underline;
            }
        }
        .result {
            width: 100%;
            float: left;
            color: $color-text;
            background: $color-white;
            margin-top: 40px;
            h3 {
                margin: 0px;
                font-weight: $extra_bold;
                font-size: 21px;
                width: 100%;
                padding: 20px 20px 0px 20px;
                box-sizing: border-box;
                .icon {
                    width: 30px;
                    height: 30px;
                    float: left;
                    margin-right: 20px;
                    margin-top: 3px;
                    &.airplane {
                        background: url(../images/airplane.png) center center no-repeat;
                        background-size: 100%;
                    }
                    &.ship {
                        background: url(../images/ship.png) center center no-repeat;
                        background-size: 100%;
                    }
                    &.truck {
                        background: url(../images/truck.png) center center no-repeat;
                        background-size: 100%;
                    }
                    &.courier {
                        background: url(../images/courier.png) center center no-repeat;
                        background-size: 100%;
                    }
                }
            }
            p.small {
                padding: 0px 20px 10px 70px;
                margin: -10px 0px 10px 0px;
                width: 100%;
                float: left;
                font-size: 12px;
                box-sizing: border-box;
                border-bottom: 1px solid $color-gray-light;
            }
            .info {
                width: 100%;
                padding: 10px 20px 10px 20px;
                box-sizing: border-box;
                font-size: 14px;
                line-height: 32px;
                float: left;
                border-bottom: 1px solid $color-gray-light;
                color: darken($color-gray, 10);
                .fa {
                    font-size: 18px;
                    margin-right: 10px;
                    float: left;
                    width: 15px;
                    text-align: center;
                    margin-top: 4px;
                    color: $color-text;
                }
                .line {
                    float: left;
                    width: 100%;
                }
            }
            .price {
                width: 100%;
                text-align: center;
                font-size: 30px;
                font-weight: $bold;
                float:left;
                padding:15px 0px 10px 0px;

                .discount {
                    font-size: 16px;
                    width: 100%;
                    float: left;
                    color: #f39c12;
                    margin-top: 5px;
                    line-height: 19px;
                }
            }
            .button {
                width: calc(100% - 40px);
                margin: 0px 20px 20px 20px;
                text-align: center;
            }
        }
    }
}

.landing_search {
    padding:30px 0px 120px 0px;
    width: 100%;
    float: left;
}

.about {
    width: 100%;
    float: left;
    padding: 100px 0px 150px 0px;
    
    .buttons {
        float: left;
        margin-top: 30px;
        position: relative;
        width: 100%;

        .button.trans {
            margin-right: 15px;
        }
    }
    
    p {
        line-height: 21px;
        font-size: 14px;
    }

    h1 {
        font-size: 21px;
    }

    h2 {
        font-size: 18px;
    }

    &.big {
        padding:75px 0px 50px 0px;
        border-top:1px solid $color-gray-light;

        h3 {
            font-size:45px;
            margin-bottom: 0px;
        }

        h4 {
            font-weight: $regular;
        }

        .buttons {
            margin-top:20px;
            width: 326px;
            left: 50%;
            margin-left: -163px;
            position: relative;
        }
    }
}

.payment-home {
    width: 100%;
    float: right;

    img {
        float: right;
        margin:10px 5px;
        max-height: 50%;
        height: 25px;
    }
}


/*--------------------------------------------------------------
# Home header style
--------------------------------------------------------------*/

@include breakpoint(lg) {
    .hero {
        .intro {
            margin-top: 200px;
        }
        .search {
            margin-top:40px;

            .fields {

                .field.location_field {
                    margin-right:0px;
                }

                .location_wrap {
                    width: 195px;
                }

                .field.last {
                    float: right;
                    margin-right: 4px;
                    margin-top: 10px;
                }
            }
        }
    }
}

@include breakpoint(md) {
    .hero {
        .intro {
            margin-top:200px;

            h2 {
                font-size: 45px;
                line-height: 55px;
            }

            p {
                display: none;
            }
        }
        .search {
            margin-top:75px;

            .fields {
                button {
                    width: 195px;
                    margin-right:4px;
                    float: right;
                }
                input {
                    &.location {
                        width: 363px;
                    }
                    &.small {
                        width: 89px;
                    }
                }
                .kg {
                    margin-right: 0;
                }
                .field {
                    margin-bottom: 10px;
                    float: left;

                    &.last {
                        margin-top:0px;
                        float: right;
                    }
                }
            }
        }
    }
    .about {
        padding: 50px 0 65px;
        .buttons {
            left: 0;
            margin-left: 15px;
            margin-top: 25px;
        }
        p {
            text-align: left;
        }
    }
}

@include breakpoint(sm) {
    .hero {
        .intro {
            margin-top: 200px;
            h2 {
                font-size: 19px;
                line-height: 29px;
            }
        }
        .search {
            margin-top: 25px;
            padding: 40px 0;

            h3 {
                font-size:19px;
            }
            .help {
                margin: 5px 0 0 0;
                border: none;
                padding: 0;
                width: 100%;
                padding-bottom: 10px;
                border-bottom: 1px solid #678196;
            }
            .more_packages {
                float: left;
            }
            .fields {
                margin-top:5px;

                button {
                    margin-top: 10px;
                    margin-right: 0px;
                    width: 100%;
                }


                .field {
                    width: 100%;
                    &.last {
                        border-top: 1px solid #678196;
                    }

                    &.kg {
                        .small {
                            width: 100%;
                        }
                    }

                    .location_wrap {
                        width: 100%;

                        .fa-map-marker {
                            top: 6px;
                        }
                    }
                }
                .label {
                    width: 100%;

                    .tooltip {
                        float: right;

                        &:hover:before {
                            margin-left:-190px;
                        }
                    }
                }
                input {
                    &.location {
                        width: 100% !important;
                    }
                    &.small {
                        width: 32.4%;
                    }
                }
                .fa-map-marker {
                    top: 52px;
                    left: 0;
                }
            }
        }
    }
    .about {
        padding: 40px 0;

        &.big {
            .buttons {
                margin-left: 0px;
                left: 0px;
                width: 100%;
            }
        }
        
        .buttons {
            margin: 0px;
            width: 100%;
            padding:0px 10px;
            box-sizing:border-box;
            left: 0px;

            .button {
                width: 100%;
                box-sizing:border-box;
                margin:0px 0px 5px 0px;

                &.trans {
                    margin-bottom: 5px;
                }
            }
        }
    }
}
