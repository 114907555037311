/*--------------------------------------------------------------
# Buttons
--------------------------------------------------------------*/

.button, .woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button, .sidebar .form input.wpcf7-submit {
	float:left;
	border-radius: 3px;
	border:0px;
	width: auto;
	height: 40px;
	font-weight: $bold;
	color:#fff;
	line-height: 44px;
	background:$color-secondary;
	box-shadow: inset -2px -2px 3px 0px rgba(245,169,47,0.9);
	transition:all 0.4s ease;
	padding:0px 15px;
	position: relative;

	.loader {
		width: 20px;
		height: 20px;
		background:url(../images/ajax-loader-white.gif) center center no-repeat;
		background-size:100% auto;
		float:left;
		left:50%;
		position: absolute;
		margin-left:-10px;
		top:10px;
	}

	&:hover, &:active, &:focus {
		color:$color-white;
		background:lighten($color-secondary, 10);
	}

	.fa {
		float:right;
		margin:9px 0px 0px 10px;
		font-size:21px;
	}

	&:focus {
		outline: none;
	}

	a:visited {
		background:$color-secondary;
	}

	&.trans {
		background:none;
		box-shadow:0px 0px 0px;
		color:darken($color-gray, 40);
		border:1px solid darken($color-gray, 60);

		&:hover {
			background:darken($color-gray, 60);
			color:$color-white;
		}
	}
}

#cookie-notice .button {
	float: none;
}