/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/

.content {
    h1,
    h2,
    h3,
    h4 {
        font-size: 21px;
        font-weight: $bold;
        width: 100%;
        float: left;
        color: $color-text;
        margin-bottom: 15px;
    }

    h1 {
        font-size: 26px;
    }
    .center {
        text-align: center;
    }
    p {
        color: darken($color-gray, 25);

        &.intro {
            color: $color-text-light;
        }
    }
}

@include breakpoint(lg) {}

@include breakpoint(md) {
    .content {
        h1 {
            &.title {
                margin-left: 15px;
                text-align: left;
            }
        }
    }
}

@include breakpoint(sm) {
    .content {
        h1.title,
        h2.title,
        h3.title,
        h4.title {
            font-size: 24px;
        }
        h1 {
            &.title {
                margin-top: 0;
            }
        }
    }
}
