/*--------------------------------------------------------------
# gbbs_b2c style
--------------------------------------------------------------*/
.gbbs_b2c {
    .lost_found-wrap {
        background: #f1f3f1;
        padding: 50px 0px 100px 0px;
        width: 100%;
        float: left;
    }

    .approximate_values {
        display: none;

        &.show {
            display: block;
        }
    }
    
    h3.pickup {
        margin-top: 20px;
    }
    .add_insurance {
        width: 100%;
        float: left;

        input[type="checkbox"] {
            float: left;
            width: auto;
        }

        span {
            float: left;
            margin: 16px 7px 0px 10px;
        }
    }

    h4 {
        font-weight: bold;
        font-size: 16px;
    }
    
    .box {
        width: 100%;
        float: left;
        padding: 25px;
        background: #fff;
        margin-top: 20px;
        box-sizing: border-box;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

        &.intro {
            padding-bottom: 10px;
        }

        h3 {
            font-size: 18px;
            font-weight: bold;
            margin:0px 0px 5px 0px;
        }

        p {
            font-size: 14px;
            line-height: 21px;
        }

        .row_field {
            margin-top: 10px;
            width: 100%;
            float: left;
        }

        .field {
            width: 100%;
            float: left;

            .info {
                font-size: 14px;
                opacity: 0.7;
            }

            &.half {
                width: 50%;
            }

            &.left {
                padding-right: 20px;
                border-right: 1px solid #eee;
            }

            &.right {
                padding-left: 20px;
            }
        }

        input, textarea {
            height: 45px;
            line-height: 45px;
            padding: 0 10px;
            box-sizing: border-box;
            border: 1px solid #e3e7e3;
            transition: all .4s ease;
            font-size: 16px;
            width: 100%;
            border-radius: 3px;

            &:focus {
                outline: none;
                border: 1px solid darken(#e3e7e3, 10);
            }
        }

        textarea {
            height: 100px;
        }

        .label {
            width: 100%;
            font-weight: bold;
            color:  $color-primary;
            font-size: 16px;
            float: left;
            margin-bottom: 5px;

            sup {
                color: #f00;
            }

            .tooltip {
                float: right;
                position: relative;
                cursor: pointer;
                margin-right: 15px;
                &:hover:before {
                    background: $color-text;
                    width: 200px;
                    color: $color-white;
                    padding: 10px;
                    bottom: 37px;
                    content: attr(data-text);
                    left: 50%;
                    font-size: 14px;
                    line-height: 21px;
                    margin-left: -100px;
                    position: absolute;
                    z-index: 99;
                    font-weight: $regular;
                    border-radius: 3px;
                    cursor: auto;
                }
                &:hover:after {
                    color: $color-text;
                    bottom: 22px;
                    font: normal normal normal 14px/1 FontAwesome;
                    content: "\f0d7";
                    left: 50%;
                    margin-left: -8px;
                    position: absolute;
                    font-size: 27px;
                    z-index: 99;
                }
            }
        }
    }

    .open_modal {
        color: $color-primary;
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
    }

    .content-popup, .add_content-popup {
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        z-index: 1000;
        left: 0px;
        top: 0px;
        overflow-y: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
        display: none;

        .inner {
            width: 400px;
            padding: 25px;
            box-sizing: border-box;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); 
            float: left;
            margin: 100px 0px 100px -200px;
            left: 50%;
            position: relative;
            background: #fff;

            h3 {
                font-size: 18px;
                font-weight: bold;
                margin: 0px;

                .fa {
                    float: right;
                    font-size: 32px;
                    cursor: pointer;
                    margin-top: -5px;

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }

            ul {
                width: 100%;
                float: left;
                padding: 0px 0px 0px 20px;
                margin: 10px 0px 0px 0px;
            }
        }
    }

    .add_content-popup {
        .inner ul {
            list-style: none;
            padding: 0px;
            margin: 20px 0px 0px 0px;

            li {
                width: 100%;
                float: left;
                padding: 5px 10px;
                border-top: 1px solid #eee;
                cursor: pointer;

                &:hover {
                    background: #eee;
                }

                span {
                    float: right;
                    font-weight: bold;
                    font-size: 21px;
                }
            }
        }
    }

    .shipping_type {
        width: 100%;

        .ship_way {
            margin-top: 10px;
            width: 100%;
            float: left;
            display: none;
        }

        .type {
            float: left;
            width: 50%;
            text-align: center;
            
            &.left {
                padding-right: 10px;
            }

            &.right {
                padding-left: 10px;
            }

            .name {
                font-size: 18px;
                font-weight: bold;
                color: $color-primary;
            }

            .type_inner {
                padding: 10px;
                width: 100%;
                box-sizing: border-box;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); 
                cursor: pointer;
                position: relative;
                transition: all 0.5s ease;

                &:hover {
                    background: #eee;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); 
                }
            }

            &.active .type_inner {
                background: $color-secondary;
                color: #fff;

                .name {
                    color: #fff;
                }

                .check {
                    display: block;
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    background: #fff;
                    left: 10px;
                    top: 10px;
                    line-height: 35px;
                    text-align: center;
                    font-size: 21px;
                    border-radius: 30px;

                    .fa {
                        color: #0fa027;
                    }
                }
            }

            .meta {
                font-size: 12px;
                line-height: 18px;
                font-weight: bold;
            }

            .check {
                display: none;
            }
        }
    }

    .summary {
        width: 100%;
        float: left;
        background: #fff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); 
        margin-top: 20px;
        padding: 20px;
        box-sizing: border-box;

        h4 {
            font-weight: bold;
            margin: 0px 0px 10px 0px;
        }

        .item {
            width: 100%;
            float: left;
            line-height: 21px;
            padding: 0px 0px 5px 0px;
            margin-bottom: 10px;
            border-bottom: 1px solid #eee;

            .name {
                font-weight: bold;
                font-size: 14px;
                color: $color-primary;
            }

            .value {
                font-size: 14px;
            }
        }
    }

    .btn {
        color: #fff;
        font-weight: bold;
        padding: 15px 30px;
        margin-top: 20px;
        margin-bottom: 50px;
        cursor: pointer;

        &.next {
            background: $color-primary;
            float: right;

            .fa {
                margin-left: 10px;
            }

            &:hover {
                background: darken($color-primary, 5);
            }
        }

        &.prev {
            background: darken(#eee, 5);
            color: darken(#eee, 50);
            float: left;

            .fa {
                margin-right: 10px;
            }

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .shipping_check {
        width: 100%;
        float: left;

        input[type="checkbox"] {
            float: left;
            width: auto;
            margin-right: 10px;
            height: auto;
        }
    }

    .shipping_fields {
        display: none;
        width: 100%;
        float: left;
        margin-top: 20px;
    }

    .contact-details {
        h3 {
            font-weight: bold;
            margin-bottom: 10px;
        }
        .field {
            margin-bottom: 15px;
        }

        .label {
            margin-bottom: 2px;
            font-size: 14px;
        }
    }

    .gbs-logo {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        background: #fff;
        padding: 10px;
        margin-top: 20px;
        float: left;
        text-align: center;

        img {
            width: 80%;
            height: auto;
            display: inline-block;
        }
    }

    .items {
        margin-top: 10px;
        width: 100%;
        float: left;
        text-align: center;

        .box {
            padding: 0px;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
        }

        .col-md-3 {
            border-right: 1px solid #eee;

            &:last-child {
                border-right: 0px;
            }
        }

        .amount {
            width: 100%;
            float: left;
            margin-top: 5px;
            padding-left: 25px;

            input {
                width: 65px;
                float: left;
                padding: 5px 0px 5px 5px;
                border-radius: 10px;
                float: left;
                margin: 0px 10px;
                border: 1px solid darken(#eee, 10);

                &:focus {
                    outline: none;
                    border: 1px solid $color-primary;
                }
            }

            .control {
                background: $color-primary;
                color: #fff;
                margin-top: 3px;
                width: 20px;
                height: 20px;
                border-radius: 20px;
                float: left;
                margin-top: 8px;
                font-size: 14px;
                line-height: 26px;
                text-align: center;
                cursor: pointer;

                &:hover {
                    background: $color-secondary;
                }
            }
        }
    }

    .number {
        background: $color-secondary;
        color: #fff;
        padding: 20px;
        width: 100%;
        float: left;
        box-sizing: border-box;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

        h3 {
            font-weight: bold;
            color: #fff;
            margin: 0px 0px 5px 0px;
            font-size: 21px;
        }

        p {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 0px;
            //font-weight: bold;
        }
    }

    .steps {
        width: 100%;
        float: left;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;

        .line {
            position: absolute;
            bottom: 9px;
            left: 0px;
            margin-left: 11%;
            width: 78%;
            float: left;
            height: 7px;
            z-index: 1;
            background: $color-primary;

            .inner {
                height: 7px;
                background: $color-secondary;
                float: left;
                padding: 0px !important;
            }
        }

        .step {
            width: 20%;
            float: left;
            cursor: pointer;

            &:hover {
                span {
                    opacity: 0.8;
                }
            }

            span {
                width: 100%;
                float: left;
                color: darken($color-gray, 25);
                text-align: center;
                font-size: 14px;
            }

            .circle {
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
                border: 3px solid $color-primary;
                width: 26px;
                height: 26px;
                border-radius: 30px;
                float: left;
                left: 50%;
                background:#fff;
                margin-left: -13px;
                position: relative;
                z-index: 10;
            }

            &.active {
                .circle {
                    background: $color-primary;
                }

                span {
                    color: $color-primary;
                    font-weight: bold;
                }
            }

            &.done {
                .circle {
                    border: 3px solid $color-secondary;
                    background: $color-secondary;
                }

                span {
                    opacity: 0.5;
                    color: $color-primary;
                    font-weight: bold;
                }   
            }
        }
    }


    .insurance_check {
        width: 100%;
        float: left;

        input[type="checkbox"] {
            float: left;
            width: auto;
            margin-right: 10px;
            height: auto;
        }
    }

    .agreements {
        .btn {
            margin: 20px 0px 0px 0px;
            width: 100%;
            text-align: center;
        }
    }

    .tab {
        display: none;

        &.first {
            display: block;
        }
    }

    .content-item {
        position: relative;

        .num {
            width: 25px;
            height: 25px;
            border-radius: 30px;
            background: $color-secondary;
            position: absolute;
            color: #fff;
            left: -10px;
            top: -10px;
            text-align: center;
            line-height: 30px;
        }

        .size {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.5);
        }

        .add {
            float: right;
            color: $color-primary;
            cursor: pointer;
            font-size: 16px;

            &:hover {
                opacity: 0.8;
            }
        }

        .item {
            border-top: 1px solid #eee;
            width: 100%;
            padding: 10px 0px;

            .value {
                font-weight: bold;
            }

            .new {
                float: right;

                label {
                    display: inline-block;
                }

                input {
                    display: inline-block;
                    float: left;
                    width: auto;
                    margin: 3px 2px 0px 5px;
                    height: auto;
                    line-height: 21px;
                }
            }

            .new_wrap {
                width: 100%;
                float: left;
                background: #eee;
                padding: 10px;
            }
        }
   }
}

