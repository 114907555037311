/*--------------------------------------------------------------
# Page style
--------------------------------------------------------------*/
.subheader {
	width: 100%;
	height: 200px;
	float:left;
	margin:0px 0px 50px 0px;
	color:$color-white;

	h1 {
		margin:90px 0px 0px 0px;
		font-weight: $extra_bold;
		width: 100%;
		float: left;
		transition:all 0.3s ease;
	}

	h3 {
		display: none;

		span.postal-code {
			display: none !important;
		}
	}

	&.with_sub {
		h1 {
			margin-top:75px;
		}
		h3 {
			float:left;
			width: 100%;
			margin:5px 0px;
			font-size:18px;
			font-weight: $regular;
			display: block;

			span {
				font-weight: $bold;
			}
		}
	}

	&.with_sub.direct {
		h3 {
			display: block;
		}
	}
}


/*--------------------------------------------------------------
# Responsive page style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {
	.subheader {
		height: 125px;
		margin-bottom: 20px;

		h1 {
			margin-top:50px;
		}

		&.with_sub {
			h1 {
				margin-top: 35px;
			}
		}
	}
}

@include breakpoint(sm) {

}