/*--------------------------------------------------------------
# Timeline
--------------------------------------------------------------*/
.timeline {
	width: 100%;
	float: left;
	padding:50px 0px 20px 200px;
	box-sizing:border-box;

	.icon {
		width: 60px;
		height: 60px;
		border-radius: 80px;
		background:$color-secondary;
		margin:13px 0px;
		padding:9px 13px;
		font-size:30px;
		text-align: center;
		float:right;
		box-sizing:border-box;
		color:#fff;
		position: relative;
		z-index: 50;
		
		.fa {
			margin-top: 5px;
		}

		.line {
			width: 10px;
			height: 47px;
			background:lighten($color-secondary, 40);
			left:50%;
			position: absolute;
			bottom:-47px;
			margin-left:-5px;
			z-index: 10;
		}
	}

	h3 {
		font-size:21px;
		font-weight: $bold;
		color:darken($color-gray, 20);
		margin-top: 32px;
		margin-bottom: 0px;
	}
}

/*--------------------------------------------------------------
# Timeline style
--------------------------------------------------------------*/
@include breakpoint(lg) {
	.timeline {
		padding:50px 0px 100px 0px;
		text-align: center;

		.icon {
			float: left;
			left: 50%;
			margin-left: -30px;

			.line {
				display: none;
			}
		}

		h3 {
			margin:5px 0px 30px 0px;
			width: 100%;
			font-size: 18px;
			padding:0px 40px;
			box-sizing: border-box;
		}
	}
}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}