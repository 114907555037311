/*--------------------------------------------------------------
# faq style
--------------------------------------------------------------*/

.faq {
    width: 100%;
    float: left;

    .tabs {
        width: 100%;
        float: left;
        margin-top: 50px;

        h3 {
            width: 100%;
            float: left;
            margin: 35px 0 15px;
        }
        p {
            width: 100%;
            float: left;
        }
        .tab {
            border: 1px solid #ddd;
            padding:25px 20px 20px;
            margin-bottom: 11px;
            border-radius: 3px;
            position: relative;
            float: left;
            width: 100%;
            -webkit-transition: all 0.1s ease-in-out;
            -moz-transition: all 0.1s ease-in-out;
            -o-transition: all 0.1s ease-in-out;
            transition: all 0.1s ease-in-out;
            &:hover {
                cursor: pointer;
                background-color: #f4f4f4;
            }

            i {
                position: absolute; 
                font-size: 20px;
                right: 30px;
                top: 25px;
                color: $color-primary;
                transition: transform 500ms ease;
                -moz-transition: -moz-transform 500ms ease;
                -ms-transition: -ms-transform 500ms ease;
                -o-transition: -o-transform 500ms ease;
                -webkit-transition: -webkit-transform 500ms ease;
            }
            &.open {
                i {
                    transform: rotate( 180deg);
                    -moz-transform: rotate( 180deg);
                    -ms-transform: rotate( 180deg);
                    -o-transform: rotate( 180deg);
                    -webkit-transform: rotate( 180deg);
                    transition: transform 500ms ease;
                    -moz-transition: -moz-transform 500ms ease;
                    -ms-transition: -ms-transform 500ms ease;
                    -o-transition: -o-transform 500ms ease;
                    -webkit-transition: -webkit-transform 500ms ease;
                }
            }

            h3 {
                margin: 0;
                width: calc(100% - 20px);
                font-size: 21px;
            }

            .inner {
                display: none;
                width: 100%;
                float: left;
                margin-top:10px;
            }
        }
    }
}


/*--------------------------------------------------------------
# Responsive faq style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {}

@include breakpoint(sm) {
    .faq {
        .tabs {
            .tab {
                word-wrap: break-word;
                h3 {
                    width: calc(100% - 40px);
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
    }
}
