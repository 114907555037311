html.menu-open {
    overflow: hidden;
}

.blur {
    width: 100%;
    float: left;
    overflow: hidden;
}

body {
    font-size: $base-font-size;
    line-height: $base-line-height;
    padding: 0px;
    margin: 0px;
    color: $color-text-gray;
    line-height: 26px;
    font-family: $base-font-family;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

label, .label {
    color: $color-text-gray;
    display: inline;
    padding: 0px;
    font-size: 100%;
    font-weight: 400;
    line-height: inherit;
    text-align: left;
    white-space: normal;
    vertical-align: baseline;
    border-radius: 0;
}

.small {
    line-height: inherit;
    text-align: left;
    font-size: 100%;
}

a:visited,
a:active,
a:focus,
a:hover {
    outline: none;
    text-decoration: none;
}

.nav {
    a:visited,
    a:active,
    a:focus,
    a:hover {
        background: none !important;
    }
}

.marginTop {
    margin-top: 80px;
}

.admin-bar {
    .topbar {
        margin-top: 32px !important;
    }
}

#cookie-notice {
    z-index: 9999999999 !important;
}