/*--------------------------------------------------------------
# Landing 
--------------------------------------------------------------*/
.products_row {
    width: 100%;
    float: left;
    margin-top: 25px;

    .product_single {
    	border-bottom: 1px solid $color-gray-light;

    	h3 {
    		font-size: 18px;
    	}

    	figure {
    		width: 100%;
    		float:left;
    		height: 200px;
    	}

        .price {
            width: 100%;
            padding:0px 0px 20px 0px;
            font-weight: bold;
            float: left;
            font-size: 21px;
        }

    	.specs {
    		width: 100%;
    		float:left;
    		margin: 20px 0px;
    		font-size: 14px;

    		.spec {
    			margin-bottom: 4px;
    			
    			.name {
    				width: 65%;
    				float:left;
    				color: rgba(0, 0, 0, 0.5);

    				span {
    					font-size: 12px;
    				}
    			}

    			.input {
    				width: 35%;
    				float:left;
    			}
    		}
    	}
    }
}

.landing {
	.faq .tabs {
		margin-top: 20px;
	}
}

/*--------------------------------------------------------------
# Responsive landing style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}
