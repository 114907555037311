/*--------------------------------------------------------------
# Services 
--------------------------------------------------------------*/

.services {
    width: 100%;
    float: left;
    padding: 40px 0px 125px 0px;
    .service {
        margin-top: 50px;
        padding: 25px;
        border: 1px solid $color-gray;
        width: 100%;
        box-sizing: border-box;
        float: left;
        .icon {
            width: 45px;
            float: left;
            margin-right: 15px;
            &.baggage svg {
                margin-top: -15px;
            }
            &.cont svg {
                margin-top: -7px;
            }
            &.house svg {
                margin-top: -7px;
            }
            svg {
                width: 100%;
                height: auto;
                fill: $color-gray;
                height: 35px;
            }
        }
        &:hover {
            text-decoration: none;
            color: $color-primary-light;
            border: 1px solid $color-primary-light;
            p,
            h3 {
                color: $color-primary-light;
            }
            svg {
                fill: $color-primary-light;
            }
        }
        h3 {
            margin: 0px 0px 10px 0px;
            padding-top: 5px;
            font-weight: $bold;
            color: darken($color-gray, 10);
            width: 100%;
            float: left;
            height: 35px;
        }
        p {
            width: 100%;
            float: left;
            margin: 0px;
            color: $color-gray;
        }
        .button {
            margin-top: 15px;
        }
    }
}


/*--------------------------------------------------------------
# Responsive header style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {
    .services {
        padding: 50px 0 65px;
    }
}

@include breakpoint(sm) {
    .services {
        padding: 20px 0px 50px 0;

        .service {
            margin-top: 10px;
            padding: 20px;
        }
    }
}
