/*--------------------------------------------------------------
# 404 style
--------------------------------------------------------------*/

.p404 {
    padding-bottom: 200px;

    h1 {
        margin-top:100px;
    }

    h3 {
        margin-top: 50px;
        font-size: 21px;
    }

    ul {
        list-style:none;
        width: 100%; 
        padding: 0px 0px 10px 0px;
        float: left;

        li {
            width: 100%;
            float: left;
            margin-bottom: 5px;

            .fa {
                margin:0px 5px;
                color: $color-primary;
            }
        }
    }
}


/*--------------------------------------------------------------
# Responsive 404 style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {}

@include breakpoint(sm) {

}
