/*--------------------------------------------------------------
# Tinymce
--------------------------------------------------------------*/
body#tinymce {
  margin: 12px !important;
}

#tinymce {
	p {
		color: #000 !important;
	}
}