/*--------------------------------------------------------------
# contact style
--------------------------------------------------------------*/

.contact {
    .gmap {
        overflow:hidden;
        height:400px;
        width:100%;
    }
    #gmap_canvas {
        height:340px;
        width:100%;
        margin-top: 60px;
    }
    .col-md-7 {
        padding-right: 90px;
    }
    
    .contact_form {
        box-shadow: 0 0 5px rgba(0,0,0,.05);
        border: 1px solid #f1f3f1;
        padding: 20px;
        float: left;
    }

    h3 {
        margin-top: 0;
    }
    .ajax-loader {
        width: 15px !important;
    }
    .wpcf7-form {
        .requird {
            color: #a7a3a3;
        }
        .wpcf7-form-control {
            width: 100%;
            margin-bottom: 15px;
            padding: 8px 0 6px 15px;
            border: 1px solid $color-gray-light;
            box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.05);

            &:focus {
                border: 1px solid darken($color-gray-light, 8);
                outline: none;
            }

            &.wpcf7-not-valid {
                border: 1px solid #dd7575;
            }
        }
        .wpcf7-not-valid-tip {
            display: none;
        }
        .button {
            width: 100%;
            height: 55px;
            position: relative;
            .fa {
                font-size: 21px;
                position: absolute;
                right: 55px;
                top: 17px;
            }
        }
        .wpcf7-response-output  {
            margin: 0 !important;
            width: 100%;
            float:left;
            padding: 10px 20px;
            box-sizing:border-box;
            font-size:14px;
            line-height: 21px;
        }
    }
}


/*--------------------------------------------------------------
# Responsive contact style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {
    .contact {
        .gmap {
            height: 345px;
        }
        #gmap_canvas {
            margin-top: 25px;
            height: 320px;
        }
        .contact_form {
            margin-top: 25px;
        }
        .col-md-7 {
            padding-right: 15px;
        }
    }
}

@include breakpoint(sm) {}
