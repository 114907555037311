    /*--------------------------------------------------------------
# Product style
--------------------------------------------------------------*/
    
    .product {
        width: 100%;
        float: left;
        padding: 80px 0px;

        .basic-error {
            display: none;
        }

        .subheader {
            margin-bottom: 0px;
        }

        .usp-bar {
            margin-bottom: 40px;
        }

        &.lostfound {
            .product_info {
                .inner {
                    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
                    border: 0px;
                    padding: 0px;

                    h3 {
                        font-size: 24px;
                    }

                    .row {
                        margin-left: -5px;
                        margin-right: -5px;
                    }
                    
                    .item {
                        margin-bottom: 10px;
                        padding:0px 5px;
                        height: auto;
                        
                        .item_name {
                            line-height: 15px;
                            width: 100%;
                            float: left;
                            font-size: 15px;
                            font-size: bold;
                            text-align: center;

                            span {
                                width: 100%;
                                float: left;
                                font-weight: 400;
                                font-size: 11px;
                            }
                        }

                        .icon {
                            border: 0px;
                        }

                        label {
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
                            width: 100%;
                            float:  left;
                            padding: 10px;
                            height: 202px;
                            position: relative;

                            .button {
                                float: left;
                                left: 0px;
                                margin: 0px;
                                width: calc(100% - 20px);
                                position: absolute;
                                bottom: 10px;
                                left: 10px;
                                right: 10px;

                                .fa {
                                    font-size: 16px;
                                    float: right;
                                    margin-top: 11px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .product_info {
            .payment {
                width: 100%;
                border: 1px solid $color-gray-light;
                padding: 20px;
                box-sizing: border-box;
                text-align: center;
                float: left;
                line-height: 39px;
                margin-top: 20px;
                img {
                    margin: 0px 15px;
                }
            }
            .inner {
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
                border: 1px solid $color-gray-light;
                padding: 20px;
                box-sizing: border-box;
                float: left;
                width: 100%;
                box-sizing: border-box;
                color: $color-text;

                a.extra_info {
                    width: 100%;
                    float:left;
                    margin-top:10px;
                    font-size: 14px;
                    color:$color-primary-light;
                    text-decoration: underline;
                }
                
                h3 {
                    margin: 10px 0px 0px 0px;
                    font-size: 18px;
                }
                .subtitle {
                    width: 100%;
                    float: left;
                    font-weight: 18px;
                    padding-top: 20px;
                    margin: 20px 0px 10px 0px;
                    font-weight: $extra_bold;
                    color: $color-primary-light;
                    border-top: 1px solid $color-gray-light;
                }
                .field {
                    width: 100%;
                    float: left;
                    line-height: 40px;
                    margin-bottom: 10px;
                    &.none {
                        display: none;
                    }
                    .label {
                        width: 60%;
                        float: left;
                        .tooltip {
                            float: right;
                            position: relative;
                            cursor: pointer;
                            margin-right: 15px;
                            &:hover:before {
                                background: $color-text;
                                width: 200px;
                                color: $color-white;
                                padding: 10px;
                                bottom: 37px;
                                content: attr(data-text);
                                left: 50%;
                                font-size: 14px;
                                line-height: 21px;
                                margin-left: -100px;
                                position: absolute;
                                z-index: 99;
                                font-weight: $regular;
                                border-radius: 3px;
                                cursor: auto;
                            }
                            &:hover:after {
                                color: $color-text;
                                bottom: 22px;
                                font: normal normal normal 14px/1 FontAwesome;
                                content: "\f0d7";
                                left: 50%;
                                margin-left: -8px;
                                position: absolute;
                                font-size: 27px;
                                z-index: 99;
                            }
                        }
                    }
                    .distance {
                        float: left;
                        font-weight: bold;
                    }
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }
                    input[type="text"],
                    input[type="number"] {
                        height: 40px;
                        line-height: 40px;
                        padding: 0px 10px;
                        box-sizing: border-box;
                        border: 1px solid $color-gray-light;
                        transition: all 0.4s ease;
                        font-size: 16px;
                        width: 40%;
                        border-radius: 3px;
                        &.error {
                            border: 1px solid $color-secondary;
                        }
                        &.valid {
                            border: 1px solid #95cb61;
                        }
                        &.small {
                            float: left;
                            margin-right: 5px;
                            width: 65px;
                            &.last {
                                margin-right: 0px;
                            }
                        }
                        &:focus {
                            outline: none;
                            border: 1px solid $color-primary-light;
                        }
                    }
                    input[type="radio"] {
                        margin-right: 5px;
                        width: 15px;
                        height: 15px;
                        &:focus {
                            outline: none;
                        }
                    }
                    label {
                        font-weight: $regular;
                        margin-right: 10px;
                    }
                }
                .options {
                    width: 100%;
                    float: left;
                    padding-top: 20px;
                    margin: 20px 0px 5px 0px;
                    border-top: 1px solid $color-gray-light;
                    border-bottom: 1px solid $color-gray-light;
                    .field {
                        line-height: 32px;
                        margin-bottom: 5px;
                    }
                    .label {
                        font-weight: $extra_bold;
                        color: $color-primary-light;
                        .fa {
                            color: $color-text;
                        }
                    }
                }
                .totals,
                .totals_static {
                    width: 100%;
                    float: left;
                    padding-top: 10px;
                    margin: 0px 0px 0px 0px;
                    display: none;
                    .custom_loader {
                        width: 100%;
                        text-align: center;
                        float: left;
                    }
                    .small,
                    .big {
                        width: 100%;
                        float: left;
                        margin-bottom: 10px;
                        display: none;
                        line-height: 18px;
                        
                        &.minprice {
                            font-size: 14px;
                        }
                        .label {
                            width: 60%;
                            float: left;
                            padding-right:5px;
                        }
                        .price {
                            font-weight: $bold;
                            width: 40%;
                            float: left;
                        }
                    }
                    .big {
                        font-size: 24px;
                        border-top: 1px solid $color-gray-light;
                        padding-top: 20px;
                        margin-top: 10px;
                        font-weight: $bold;
                        margin-bottom: 5px;

                        .label {
                            font-weight: $bold;
                        }
                    }
                }
            }
            .discount_wrap {
                display: block;
                width: 100%;
                float: left;
                margin-top: 5px;

                .label {
                    font-size: 21px;
                    color: #f39c12;
                    font-weight: bold;
                }
            }

            .noResult,
            .highVol {
                width: 100%;
                padding: 15px;
                box-sizing: border-box;
                background: #f8f7e1;
                color: #81805d;
                font-size: 14px;
                display: none;
            }

            .sub_add_package {
                float: left;
                cursor: pointer;
                margin-top:15px;
                margin-bottom: 20px;

                &:hover {
                    opacity: 0.6;
                }
            }

            .more_items {
                width: 100%;
                float: left;

                .item, .item_names {
                    width: 100%;
                    float: left;
                    padding-top:5px;
                    margin-top:0px;
                    position: relative;

                    .fa-times {
                        float: right;
                        font-size: 21px;
                        cursor: pointer;
                        position: absolute;
                        right:10px;
                        top: 14px;

                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    .count_wrap {
                        width: 20%;
                    }

                    .size_wrap {
                        width: 50%;
                    }

                    .weight_wrap {
                        width: 30%;
                    }

                    .field {
                        .label {
                            width: 100%;

                            span {
                                float: left;
                            }

                            .tooltip {
                                float: left;
                                margin-left: 10px;
                                margin-right: 0px;
                            }
                        }

                        input {
                            width: 100%;
                        }
                    }
                }

                .item_names {
                    margin-top:10px;
                    padding-bottom: 0px;
                }

                .item {
                    border-bottom: 1px solid $color-gray-light;
                    padding-bottom: 0px;
                    margin-bottom: 5px;
                }

            }
        }
        &.lostfound {
            pointer-events: auto !important;
            padding-bottom: 0px;
            
            .checkout {
                .inner {
                    background: none;
                    padding: 0px;

                    .box {
                        float: left;
                        padding: 20px 20px 30px;
                        box-sizing: border-box;
                        background: #f1f3f1;
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }
            }

            .lostfound_numbers {
                width: 100%;
                float: left;
                br {
                    display: none;
                }

                .field {
                    padding-bottom: 10px;
                    margin-top: 10px;
                    border-bottom: 1px solid lighten($color-gray, 20);
                }

                .input_wrap {
                    width: calc(100% - 80px);
                    float: right;

                    input {
                        font-size: 15px;
                    }

                    span {
                        float: none;
                    }

                    .fa {
                        float: right;
                        font-size: 18px;
                        margin-top: 3px;
                        color: $color-primary;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.5;
                        }
                    }

                    .label {
                        font-size: 12px;
                        font-weight: bold;
                    }
                }

                .icon {
                    width: 70px;
                    height: 70px;
                    background: #fff;
                    border-radius: 90px;
                    float: left;
                    text-align: center;
                    line-height: 70px;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
 
                    img {
                        max-width: 70%;
                        height: 35px;
                    }
                }

                .no_items p {
                    width: 100%;
                    text-align: center;
                    margin-top: 10px;
                    font-size: 14px;
                    color: darken($color-gray, 15);
                    line-height: 21px;
                    padding: 10px 0px;
                    border-top: 1px solid lighten($color-gray, 20);
                    border-bottom: 1px solid lighten($color-gray, 20);
                }
            }
            .totals_lf {
                width: 100%;
                float: left;

                h3 {
                    width: 100%;
                    float: left;
                    font-weight: bold;
                    font-size: 16px;
                    margin-bottom: 3px;
                    color:$color-text;
                }

                .small,
                .big {
                    width: 100%;
                    float: left;
                    margin-bottom: 4px;
                    font-size: 14px;
                    line-height: 18px;
                    display: none;
                    
                    &.minprice {
                        font-size: 14px;
                    }
                    .label {
                        width: 60%;
                        float: left;
                        padding-right:5px;
                    }
                    .price {
                        font-weight: $bold;
                        width: 40%;
                        float: left;
                    }
                }
                .big {
                    font-size: 14px;
                    font-weight: $bold;
                    margin-bottom: 5px;

                    .label {
                        font-weight: $bold;
                    }
                }
            }

            .label.payment_met {
                font-size: 14px;
                font-weight: bold;
                margin: 5px 0px;
                width: 100%;
                float: left;
            }
            .type_send {
                width: 100%;
                float: left;
                text-align: center;
                margin-top: 20px;
                display: none;

                label {
                    background: #fff;
                    box-shadow: 0 2px 4px rgba(0,0,0,.2);
                    width: 100%;
                    float: left;
                    padding: 10px;
                    position: relative;
                    text-align: center;
                    cursor: pointer;
                    opacity: 0.5;

                    &.active {
                        opacity: 1;

                        .check {
                            display: block;
                        }
                    }

                    input {
                        display: none;
                    }

                    .check {
                        width: 20px;
                        height: 20px;
                        border-radius: 30px;
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        background: $color-gray-light;
                        display: none;

                        .fa {
                            color: #0fa027;
                        }
                    }

                    span.price {
                        width: 100%;
                        float: left;
                        font-weight: 400;
                        line-height: 16px;
                    }

                    .econ, .prio {
                        margin-top: 5px;
                        width: 100%;
                        float: left;
                        font-weight: bold;
                    }
                }
                
                #econ {
                    .icon {
                        width: 58px;
                        height: 46px;
                        left: 50%;
                        margin-left: -29px;
                        background:url(../images/economy.png) center center no-repeat;
                        background-size: 100%;
                        position: relative;
                    }
                }

                #prio {
                    .icon {
                        width: 100px;
                        height: 46px;
                        left: 50%;
                        margin-left: -50px;
                        background:url(../images/prio.png) center center no-repeat;
                        background-size: 100%;
                        position: relative;
                    }
                }

                input {
                    margin-right: 5px;
                }

                .discount {
                    width: 100%;
                    float: left;
                    text-align: center;
                    font-weight: bold;
                    color: #f39c12;
                    display: none;
                    margin-top: -5px;
                }
            }
            .no_value {
                width: 100%;
                float: left;
                margin-top: 10px;
            }
            .no_value p {
                width: 100%;
                text-align: center;
                font-size: 14px;
                color: darken($color-gray, 15);
                line-height: 21px;
                padding: 10px 0px;
                border-top: 1px solid lighten($color-gray, 20);
                border-bottom: 1px solid lighten($color-gray, 20);
            }

            .on_request p {
                width: 100%;
                text-align: center;
                font-size: 18px;
                color: darken($color-gray, 15);
                line-height: 31px;
                padding: 20px 0px;
                border-top: 1px solid $color-gray;
                border-bottom: 1px solid $color-gray;
            }

            .on_request {
                display: none;
            }

            .insurance {
                width: 100%;
                float: left;
                padding: 10px 0px 0px 0px;
                margin-top: 0px;

                input {
                    margin-right: 5px;
                }
            }

            .choosen {
                padding: 0px;
                margin: 20px 0px;
                width: 100%;
                border-top: 1px solid $color-gray-light;
                float: left;
                padding: 0px;
                box-sizing: border-box;
                list-style: none;
                li {
                    padding: 10px 0px;
                    width: 100%;
                    float: left;
                    border-bottom: 1px solid $color-gray-light;
                    .icon {
                        float: left;
                        width: 30px;
                        height: 30px;
                    }
                    .name {
                        float: left;
                        margin: 3px 0px 0px 10px;
                        br {
                            display: none;
                        }
                    }
                    .fa-times {
                        float: right;
                        margin-top: 4px;
                        margin-left: 20px;
                        font-size: 21px;
                        cursor: pointer;
                        &:hover {
                            opacity: 0.5;
                        }
                    }
                    .quantity {
                        float: right;
                        line-height: 29px;
                        span {
                            float: left;
                            margin-top: 2px;
                        }
                        input {
                            margin-left: 10px;
                            border: 1px solid darken($color-gray-light, 5);
                            width: 40px;
                            float: right;
                            padding: 0px 5px;
                            box-sizing: border-box;
                            &:focus {
                                border: 1px solid $color-primary-light;
                                outline: none;
                            }
                        }
                    }
                }
            }
            .choose_type {
                width: 100%;
                margin-top: 20px;
                float: left;
                .button {
                    width: 180px;
                    text-align: center;
                    left: 50%;
                    position: relative;
                    margin: 30px 0px 30px -90px;
                    cursor: pointer;
                }
                .item {
                    height: 145px;
                    margin-bottom: 20px;
                    .icon {
                        width: 100%;
                        height: 85px;
                        border: 1px solid $color-gray-light;
                        text-align: center;
                        line-height: 85px;
                        margin-bottom: 10px;

                        img {
                            max-width: 100%;
                            height: 60px;
                        }
                    }
                    label {
                        font-size: 14px;
                        font-weight: $regular;
                        line-height: 21px;
                        width: 100%;
                        float: left;
                    }
                    input {
                        float: left;
                        margin: 7px 5px 0px 0px;
                        width: 15px;
                        &:focus {
                            outline: none;
                        }
                    }
                    span {
                        width: calc(100% - 25px);
                        float: left;
                        margin-top: 5px;
                    }
                }
            }
        }

        .help {
            padding: 30px;
            width: 100%;
            box-sizing: border-box;
            float: left;
            text-align: center;
            color: $color-text-light;
            .fa {
                font-size: 21px;
                color: $color-primary-light;
            }
            a {
                color: $color-primary-light;
                text-decoration: underline;
            }
        }
    }
    
    .checkout {
        .checkout_slider {
            width: 100%;
            float: left;
            overflow: hidden;
        }

        label, .label {
            color: $color-text-gray;
        }

        .wc_payment_methods {
            width: 100%;
            float: left;
            list-style: none;
            padding: 0px;
            margin: 0px;

            .wc_payment_method {
                position: relative;

                .tooltip {
                    font-size: 18px;
                    float: left;
                    position: absolute;
                    right: 15px;
                    top: 9px;
                    cursor: pointer;
                    &:hover:before {
                        background: $color-text;
                        color: $color-white;
                        padding: 10px;
                        bottom: 37px;
                        width: 200px;
                        content: attr(data-text);
                        left: 50%;
                        font-size: 14px;
                        line-height: 21px;
                        margin-left: -180px;
                        position: absolute;
                        z-index: 99;
                        font-weight: $regular;
                        border-radius: 3px;
                        cursor: auto;
                    }
                    &:hover:after {
                        color: $color-text;
                        bottom: 22px;
                        font: normal normal normal 14px/1 FontAwesome;
                        content: "\f0d7";
                        left: 50%;
                        margin-left: -8px;
                        position: absolute;
                        font-size: 27px;
                        z-index: 99;
                    }
                }
            }

            .payment_box {
                display: none;
            }

            &.payment_method_PayLater {
                display: none !important;
            }

            li {
                width: 100%;
                float: left;
                background: #f6f6f6;
                border:1px solid #e7e7e7;
                margin: 0px 0px 3px 0px;
                padding: 8px;

                label {
                    width: calc(100% - 30px);
                }

                img {
                    width: 20px;
                    float: left;
                    margin: 2px 5px;
                    max-width: 100%;
                }
            }
        }

        .inner {
            float: left;
            padding: 20px 40px 40px 40px;
            box-sizing: border-box;
            background: $color-gray-light;
            &:focus {
                outline: none;
            }
            p {
                line-height: 24px;
                font-size: 14px;
                margin-bottom: 10px;
                color: darken($color-gray, 10);
            }
            h3 {
                margin: 10px 0px 10px 0px;
                font-size: 18px;
            }
            .field {
                width: 100%;
                float: left;
                margin-top: 10px;
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
                input[type="text"],
                input[type="number"],
                input[type="email"],
                textarea {
                    height: 45px;
                    line-height: 45px;
                    padding: 0px 10px;
                    box-sizing: border-box;
                    border: 1px solid darken($color-gray-light, 5);
                    transition: all 0.4s ease;
                    font-size: 16px;
                    width: 100%;
                    border-radius: 3px;
                    &.error {
                        border: 1px solid $color-secondary;
                    }
                    &.valid {
                        border: 1px solid #95cb61;
                    }
                    &.small {
                        float: left;
                        margin-right: 5px;
                        width: calc(34.2% - 15px);
                        margin-right: 15px;
                        &.last {
                            margin-right: 0px;
                        }
                    }
                    &:focus {
                        outline: none;
                        border: 1px solid $color-primary-light;
                    }
                }
                .form-row {
                    margin: 0px;
                    width: 100%;
                    float: left;
                    padding: 0px;
                    color: #000;
                }
                select {
                    width: 100%;
                    float: left;
                    background: $color-white;
                    height: 45px;
                    border: 1px solid darken($color-gray-light, 5);
                    margin: 0px;
                    padding: 0px 14px;
                    box-sizing: border-box;
                    &:focus {
                        outline: none;
                        border: 1px solid $color-primary-light;
                    }
                }
                input[type="checkbox"] {
                    float: left;
                    margin-right: 5px;
                }
                .label,
                label {
                    font-size: 14px;
                }
                .label {
                    margin-top: 10px;
                }
                textarea {
                    height: 100px;
                    line-height: 26px;
                    padding: 10px;
                }
                span {
                    float: left;
                }
                .fa-info-circle {
                    font-size: 18px;
                    margin-left: 5px;
                }
                .tooltip {
                    float: left;
                    position: relative;
                    cursor: pointer;
                    margin-right: 15px;
                    &:hover:before {
                        background: $color-text;
                        width: 200px;
                        color: $color-white;
                        padding: 10px;
                        bottom: 37px;
                        content: attr(data-text);
                        left: 50%;
                        font-size: 14px;
                        line-height: 21px;
                        margin-left: -100px;
                        position: absolute;
                        z-index: 99;
                        font-weight: $regular;
                        border-radius: 3px;
                        cursor: auto;
                    }
                    &:hover:after {
                        color: $color-text;
                        bottom: 22px;
                        font: normal normal normal 14px/1 FontAwesome;
                        content: "\f0d7";
                        left: 50%;
                        margin-left: -8px;
                        position: absolute;
                        font-size: 27px;
                        z-index: 99;
                    }
                }
            }
            .button {
                width: 100%;
                padding: 5px 15px;
                height: auto;
                font-size: 18px;
                cursor: pointer;
                text-align: center;
            }
            .more {
                width: 100%;
                float: left;
                margin-top: 15px;
                color: $color-text-light;
                text-decoration: underline;
                &:hover {
                    color: $color-text;
                }
            }
        }
        .slick-dots {
            width: 100%;
            padding: 0px;
            margin: 0px;
            float: left;
            background: darken($color-gray-light, 7);
            list-style: none;
            li {
                width: 33.33336%;
                float: left;
                text-align: center;
                padding: 20px 0px;
                box-sizing: border-box;
                font-weight: $bold;
                color: darken($color-gray, 10);
                cursor: pointer;
                &:hover {
                    background: darken($color-gray-light, 5);
                }
                &.active {
                    background: $color-gray-light;
                    &:hover {
                        background: $color-gray-light;
                    }
                }
            }
        }
        .payment {
            width: 100%;
            border: 1px solid $color-gray-light;
            padding: 20px;
            box-sizing: border-box;
            text-align: center;
            float: left;
            line-height: 39px;
            margin-top: 20px;
            img {
                margin: 0px 15px;
            }
        }
    }

    .lostfound-content {
        background: $color-gray-light;
        padding-top: 45px !important;

        .block {
            box-shadow: 0 2px 4px rgba(0,0,0,.2);
            width: 100%;
            float: left;
            background: #fff;
            box-sizing: border-box;
            padding: 40px 20px;
            text-align: center;

            &.content {
                margin-top: 30px;
                padding: 40px 100px;

                img {
                    width: 55%;
                    height: auto;
                }
            }

            h1, h3 {
                margin: 0px;
                width: 100%;
                float: left;
            }

            .step {
                margin-top: 10px;
                margin-bottom: 20px;
            }

            .img_wrap {
                box-shadow: 0 2px 4px rgba(0,0,0,.2);
                height: 150px;
                width: 150px;
                float: left;
                position: relative;
                padding: 10px;
                left: 50%;
                margin-left: -75px;
                margin-bottom: 30px;

                .img {
                    width: 100%;
                    height: 100%;
                }

                .circle_step {
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    background: $color-primary;
                    color: #fff;
                    text-align: center;
                    line-height: 27px;
                    border-radius: 25px;
                    font-weight: bold;
                    left: 50%;
                    margin-left: -13px;
                }
            }
        }
    }
    /*--------------------------------------------------------------
# Responsive Product style
--------------------------------------------------------------*/
    
    @include breakpoint(lg) {
        .product {
            .product_info {
                .more_items {
                    .item_names {
                        .field {
                            .label {
                                span {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
            .checkout {
                .payment {
                    img {
                        width: 45px;
                    }
                }
            }
        }
    }
    
    @include breakpoint(md) {
        .checkout {
            .inner {
                .field {
                    input[type=number] {
                        &.small {
                            width: calc(34.33% - 20px);
                        }
                    }
                }
            }
            .checkout_slider {
                margin-top: 25px;
            }
        }
        .product {
            &.lostfound {
                .choose_type {
                    .item {
                        width: 25%;
                        float: left;
                    }
                }
            }

            .help {
                padding:20px 0px 20px 0px;

                br {
                    display: none;
                }
            }
        }
        .lostfound-content {
            .block {
                &.content {
                    margin-top: 15px;
                    padding: 20px;

                    img {
                        max-width: 100%;
                        width: auto;
                    }
                }
            }
        }
    }
    
    @include breakpoint(sm) {
        .product {
            padding: 64px 0;
            &.lostfound {
                .choose_type {
                    .item {
                        width: 50%;
                    }
                }
            }
            .product_info {
                .inner {
                    .field {
                        input[type=text] {
                            width: 100%;

                            &.small {
                                width: 45px;
                            }
                        }

                        input[type=number] {
                            &.small {
                                width: 45px;
                                margin-right: 1px;
                            }
                        }

                        .label {
                            width: 100%;
                            .tooltip:hover:before {
                                margin-left: -175px;
                            }
                        }
                    }

                    .more_items {
                        .item {
                            .weight_wrap {
                                width: 20%;
                            }
                            .size_wrap {
                                width: 60%;
                            }

                            .fa-times {
                                right: -15px;
                                font-size: 16px;
                            }
                        }
                        .item_names {
                            .weight_wrap {
                                width: 20%;
                            }
                            .size_wrap {
                                width: 60%;
                            }

                            .field {
                                .label {
                                    line-height: 16px;

                                    span {
                                        font-size: 12px;
                                    }
                                    .tooltip {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .checkout {
            .inner {
                padding: 15px;
                .field {
                    input[type=text] {
                        &.small {
                            width: calc(33.33% - 7px);
                        }
                    }
                }
            }
        }
    }
.content_page.lostfound-content {
    padding-top: 0px;

    .content.page {
        color: #454545;
        padding-top: 0px;

        p {
            width: 100%;
            float: left;
            font-size: 14px;
            line-height: 26px;
        }

        h1, h2, h3, h4 {
            font-size: 18px;
            margin-bottom: 5px;
        }
        
        p {

        }
    }
}