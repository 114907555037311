/*--------------------------------------------------------------
# Modal 
--------------------------------------------------------------*/

.modal_overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 550;
    overflow: scroll;
    display: none;

    .close {
        position: absolute;
        right:-40px;
        top:-25px;
        font-size:36px;
        color: #fff;
        font-weight: 300;
        cursor: pointer;
        opacity: 1;

        &:hover {
            opacity: 0.8;
        }
    }

    .modal {
        width: 800px;
        display: block !important;
        background: $color-white;
        margin: auto;
        padding: 40px;
        overflow: visible;
        box-sizing: border-box;
        position: relative;
        margin-top: 100px;
        color: $color-text;
        border-radius: 2px;
        margin-bottom: 100px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);

        .extra_info {
            width: 100%;
            float: left;
            color:$color-gray;
            font-size: 14px;
        }

        h3 {
            margin: 0px;
            float: left;
            font-weight: $extra_bold;
        }
        .help {
            float: left;
            margin: 0px 0px 0px 10px;
            color: $color-text;
            opacity: 0.8;
            border-left: 1px solid $color-text;
            padding-left: 10px;
            &:hover {
                opacity: 1;
                text-decoration: none;
            }
        }
        .label {
            width: 100%;
            float: left;
            margin-bottom: 10px;
        }
        .tooltip {
            float: right;
            position: relative;
            cursor: pointer;
            margin-right: 10px;
            &:hover:before {
                background: $color-text;
                width: 200px;
                color: $color-white;
                padding: 10px;
                bottom: 37px;
                content: attr(data-text);
                left: 50%;
                font-size: 14px;
                line-height: 21px;
                margin-left: -100px;
                position: absolute;
                z-index: 99;
                font-weight: $regular;
                border-radius: 3px;
                cursor: auto;
            }
            &:hover:after {
                color: $color-text;
                bottom: 22px;
                font: normal normal normal 14px/1 FontAwesome;
                content: "\f0d7";
                left: 50%;
                margin-left: -8px;
                position: absolute;
                font-size: 27px;
                z-index: 99;
            }
        }
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
        input[type="text"],
        input[type="number"] {
            height: 40px;
            line-height: 40px;
            padding: 0px 10px;
            box-sizing: border-box;
            border: 1px solid $color-gray-light;
            transition: all 0.4s ease;
            font-size: 16px;
            width: 40%;
            border-radius: 3px;
            &.error {
                border: 1px solid $color-secondary;
            }
            &.valid {
                border: 1px solid #95cb61;
            }
            &.small {
                float: left;
                margin-right: 8px;
                width: 31%;
                &.last {
                    margin-right: 0px;
                }
            }
            &.weight {
                width: 100%;
            }
            &:focus {
                outline: none;
                border: 1px solid $color-primary-light;
            }
            &#place_id_second {
                display: none;
            }
        }
        .add_package {
            width: 100%;
            float: left;
            cursor: pointer;
            margin-top: 15px;
            &:hover {
                opacity: 0.6;
            }
        }
        .row_location {
            width: 100%;
            float: left;
            margin-top: 30px;
            margin-bottom: 20px;
            input {
                width: 100%;
            }
        }
        .items {
            width: 100%;
            float: left;
            margin-top: 15px;
            .fa-times {
                float: right;
                font-size: 21px;
                margin-top: 9px;
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }
            }
            .item {
                width: 100%;
                float: left;
                margin-top: 10px;
                border-bottom: 1px solid $color-gray-light;
                padding-bottom: 5px;
            }
        }
    }
}


/*--------------------------------------------------------------
# Responsive reviews style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {
    .modal_overlay {
        .modal {
            width: 95%;
            margin-left: 2.5%;
            .close {
                top: -45px;
                right: 0px;
            }
            .label {
                margin-bottom: 5px;
                margin-top: 15px;
            }
            input[type=number] {
                &.small {
                    width: 32.7%;
                    margin-right: 6px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@include breakpoint(sm) {
    .modal_overlay {
        .modal {
            padding: 30px;
            .item {
                margin-bottom: 5px;

                .field {
                    float: left;
                }
            }
            .help {
                margin: 10px 0 0 0;
                border: none;
                padding: 0;
                width: 100%;
            }
            input[type=number] {
                &.small {
                    width: calc(33.333% - 6px);
                    margin-right: 9px;
                }
            }
        }
    }
}
