/*--------------------------------------------------------------
# sidemenu 
--------------------------------------------------------------*/

.sidemenu {
    float: left;

    .box {
        box-shadow: 0 0 5px rgba(0,0,0,.05);
        border: 1px solid #f1f3f1;
        padding: 0px;
        float: left;
        width: 100%;

        ul {
            padding: 0px;
            margin: 0px;
            list-style: none;

            li {
                width: 100%;
                float:left;
                box-sizing:border-box;
                border-bottom: 1px solid $color-gray-light;
                line-height: 21px;
                margin: 0px;

                &.active {
                    font-weight: $bold;
                }

                &:hover {
                    background: lighten($color-primary, 55);
                }

                .fa {
                    float: right;
                    color: $color-text;
                }

                a {
                    width: 100%;
                    float:left;
                    color: $color-text-gray;
                    padding:14px 20px 10px 20px;
                }
            }
        }
    }

}


/*--------------------------------------------------------------
# Responsive sidemenu style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}
