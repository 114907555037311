/*--------------------------------------------------------------
# Page style
--------------------------------------------------------------*/
.calculator {
	p {
		float: left;
		width: 100%;
	}
	.calculator_wrap {
		margin-bottom: 40px;
		float: left;
		
		.inner {
			box-shadow: 0 0 5px rgba(0,0,0,.05);
			border: 1px solid #f1f3f1;
			padding: 20px;
			float: left;
			width: 100%;
			box-sizing: border-box;

			h1 {
				margin: 0px 0px 10px 0px;
			}

			.label {
				width: 100%;
				float: left;
				font-weight: $bold;
				margin: 0px 0px 3px 0px;
			}

			.type {
				width: 100%;
				list-style: none;
				padding: 0px;
				margin: 0px;

				li {
					width: 25%;
					float: left;
					background: $color-gray-light;
					border: 1px solid darken($color-gray-light, 10);
					padding: 10px 0px;
					text-align: center;
					border-right: 0px;
					cursor: pointer;

					&:hover {
						opacity: 0.8;
					}

					&.active {
						background: darken($color-gray-light, 10);

						img {
							opacity: 1;
						}
					}

					img {
						height: 30px;
						width: auto;
						opacity: 0.5;
					}

					&.first {
						border-radius: 5px 0px 0px 5px;
					}

					&.last {
						border-radius: 0px 5px 5px 0px;
						border-right: 1px solid darken($color-gray-light, 10);
					}
				}
			}

			.field {
				input::-webkit-inner-spin-button {
				    -webkit-appearance: none;
				}
				input[type="text"],
				input[type="number"],
				input[type="email"],
				textarea {
				    height: 45px;
				    line-height: 45px;
				    padding: 0px 10px;
				    box-sizing: border-box;
				    border: 1px solid darken($color-gray-light, 5);
				    transition: all 0.4s ease;
				    font-size: 16px;
				    width: 100%;
				    border-radius: 3px;
				    &.error {
				        border: 1px solid $color-secondary;
				    }
				    &.valid {
				        border: 1px solid #95cb61;
				    }
				    &.small {
				        float: left;
				        margin-right: 5px;
				        width: calc(34.33% - 15px);
				        margin-right: 15px;
				        &.last {
				            margin-right: 0px;
				        }
				    }
				    &:focus {
				        outline: none;
				        border: 1px solid $color-primary-light;
				    }
				}

			    .label {
			        width: 100%;
			        .tooltip:hover:before {
			            margin-left: -175px;
			        }
			    }
			}

			.tooltip {
			    float: right;
			    position: relative;
			    cursor: pointer;
			    margin-right: 15px;
			    &:hover:before {
			        background: $color-text;
			        width: 200px;
			        color: $color-white;
			        padding: 10px;
			        bottom: 37px;
			        content: attr(data-text);
			        left: 50%;
			        font-size: 14px;
			        line-height: 21px;
			        margin-left: -100px;
			        position: absolute;
			        z-index: 99;
			        font-weight: $regular;
			        border-radius: 3px;
			        cursor: auto;
			    }
			    &:hover:after {
			        color: $color-text;
			        bottom: 22px;
			        font: normal normal normal 14px/1 FontAwesome;
			        content: "\f0d7";
			        left: 50%;
			        margin-left: -8px;
			        position: absolute;
			        font-size: 27px;
			        z-index: 99;
			    }
			}

			.sub_add_package {
				width: 100%;
				float: left;
				margin-top: 10px;
				cursor: pointer;

				&:hover {
					opacity: 0.5;
				}
			}

			.results {
				width: 100%;
				border-top: 1px solid $color-gray-light;
				padding-top: 10px;
				margin-top: 20px;
				float: left;

				.total_vol, .total_weight {
					display: none;
				}

				span {
					width: 100%;
					float: left;
				}

				.btn {
					background: $color-secondary;
					color: #fff;
					padding: 8px 20px;
					border-radius: 5px;
					margin-top: 10px;
					float: left;
					font-weight: bold;

					.fa {
						margin-left: 10px;
					}

					&:hover {
						background: darken($color-secondary, 10);
					}
				}
			}

			.more_items {
			    width: 100%;
			    float: left;

			    .item, .item_names {
			        width: calc(100% + 10px);
			        float: left;
			        padding-top:5px;
			        margin-top:0px;
			        position: relative;

			        .fa-times {
			            float: right;
			            font-size: 16px;
			            cursor: pointer;
			            position: absolute;
			            right:20px;
			            top: 18px;

			            &:hover {
			                opacity: 0.8;
			            }
			        }

			        .count_wrap {
			            width: 20%;
			            float: left;
			        }

			        .field.size_wrap {
			        	float: left;
			            width: 80%;
			            padding-left: 10px;

			            input {
			            	width: calc(33.33% - 10px);
			            	margin-right: 10px;
			            }
			        }

			        .field {
			            .label {
			                width: 100%;

			                span {
			                    float: left;
			                }

			                .tooltip {
			                    float: left;
			                    margin-left: 10px;
			                    margin-right: 0px;
			                }
			            }

			            input {
			                width: 100%;
			            }
			        }
			    }

			    .item_names {
			        margin-top:0px;
			        padding-bottom: 0px;
			        font-weight: 300;

			        .label {
			        	font-weight: 300;
			        }
			    }

			    .item {
			        padding-bottom: 0px;
			        margin-bottom: 5px;
			    }

			}
		}
	}
}


/*--------------------------------------------------------------
# Responsive page style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}