/*--------------------------------------------------------------
# Timeline
--------------------------------------------------------------*/
.discount_modal {
	width: 100%;
	height: 100%;
	float: left;
	position: fixed;
	top: 0px;
	left: 0px;
	background: rgba(0, 0, 0, 0.8);
	z-index: 1000;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;

	.modal_box {
		margin: 100px 0px 100px -275px;
		width: 550px;
		float: left;
		left: 50%;
		position: relative;
		background: #fff;
		border-radius: 10px;
		position: relative;

		.inner {
			padding: 50px;
			width: 100%;
			float: left;
			box-sizing: border-box;
			text-align: center;
			border-radius: 10px 10px 0px 0px;
			color: #fff;
			text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);

			h3 {
				font-weight: bold;
				font-size: 32px;
				line-height: 34px;
				margin-bottom: 0px;
			}

			.sub {
				font-size: 18px;
			}
		}

		.fa-times {
			position: absolute;
			right: -40px;
			color: #fff;
			font-size: 30px;
			cursor: pointer;

			&:hover {
				opacity: 0.5;
			}
		}

		.bottom {
			width: 100%;
			float: left;
			padding: 10px 0px;
			text-align: center;

			a {
				padding: 5px 10px;
				border-radius: 3px;
				border: 0;
				width: auto;
				height: 40px;
				font-weight: 700;
				color: #fff;
				line-height: 44px;
				background: $color-secondary;
				box-shadow: inset -2px -2px 3px 0 rgba(245,169,47,.9);
				-webkit-transition: all .4s ease;
				transition: all .4s ease;
				padding: 0 15px;
				display: inline-block;

				&:hover {
					background: darken($color-secondary, 10);
				}

				.fa {
					margin-left: 15px;
				}
			}
		}
	}
}

/*--------------------------------------------------------------
# Timeline style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {
}

@include breakpoint(sm) {
	.discount_modal {
		.modal_box {
			width: 95%;
			margin-left: 2.5%;
			left: 0px;

			.inner {
				padding: 40px;
			}

			.fa-times {
				top: -40px;
				right: 0px;
			}
		}
	}
}