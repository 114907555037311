/*--------------------------------------------------------------
# content style
--------------------------------------------------------------*/
.content_page {
    padding: 50px 0px;
    width: 100%;
    float:left;

    .content {

        &.page {
            padding:50px 0px 100px 0px;
        }

        h1 {
            font-size: 25px;
            font-weight: 600;
            margin-bottom: 35px;
        }
        p {
            color: #333;
        }

        ol {
            width: 100%;
            float: left;

            li {
                width: 100%;
                float: left;
                margin-bottom: 10px;
                ul {
                    margin-top: 10px;
                    list-style-type:lower-alpha;
                }
            }
            p {
                line-height: 25px;
            }
        }

        ul {
            width: 100%;
            float: left;

            li {
                width: 100%;
                float: left;
                margin-bottom: 10px;
            }
        }
        table {
            td {
                padding: 10px;
                border: 1px solid #e8e6e6;
            }
        }
    }
}

/*--------------------------------------------------------------
# Responsive content style
--------------------------------------------------------------*/
@include breakpoint(lg) {
    .content_page {
        .content {
            padding: 50px 0;
        }
    }
}

@include breakpoint(md) {
    .content_page {
        .about {
            p {
                text-align: left;
            }
        }
    }
}

@include breakpoint(sm) {
    .content_page {
        .content-page-hero {
            padding-top: 85px;
            height: 250px;
            h2 {
                line-height: 32px;
                font-size: 26px;
                margin-bottom: 0;
            }
        }
        .content {
            padding: 45px 0; 
            img {
                width: 100%;
            }
            h1 {
                margin-bottom: 10px;
            }
        }
    }
}