/*--------------------------------------------------------------
# Page style
--------------------------------------------------------------*/
.globalblue {
	.subheader {
		height: 250px;
	}

	.type_send {
	    width: 100%;
	    float: left;
	    text-align: center;
	    margin-top: 20px;
	    display: none;
	    .icon {
	        font-size: 40px;
	    }
	    input {
	        margin-right: 5px;
	    }
	}
	.no_value p {
	    width: 100%;
	    text-align: center;
	    font-size: 18px;
	    color: darken($color-gray, 15);
	    line-height: 31px;
	    padding: 20px 0px;
	    border-top: 1px solid $color-gray;
	    border-bottom: 1px solid $color-gray;
	}

	.lostfound_totals {
		width: 100%;
		float: left;
		margin-top: 10px;
	}

	.on_request p {
	    width: 100%;
	    text-align: center;
	    font-size: 18px;
	    color: darken($color-gray, 15);
	    line-height: 31px;
	    padding: 20px 0px;
	    border-top: 1px solid $color-gray;
	    border-bottom: 1px solid $color-gray;
	}

	.on_request {
	    display: none;
	}

	.insurance {
	    width: 100%;
	    float: left;
	    padding: 20px 0px;
	    margin-top: 20px;
	    border-top: 1px solid $color-gray;
	}
	.choosen {
	    padding: 0px;
	    margin: 20px 0px;
	    width: 100%;
	    border-top: 1px solid $color-gray-light;
	    float: left;
	    padding: 0px;
	    box-sizing: border-box;
	    list-style: none;
	    li {
	        padding: 10px 0px;
	        width: 100%;
	        float: left;
	        border-bottom: 1px solid $color-gray-light;
	        .icon {
	            float: left;
	            width: 30px;
	            height: 30px;
	        }
	        .name {
	            float: left;
	            margin: 3px 0px 0px 10px;
	            br {
	                display: none;
	            }
	        }
	        .fa-times {
	            float: right;
	            margin-top: 4px;
	            margin-left: 20px;
	            font-size: 21px;
	            cursor: pointer;
	            &:hover {
	                opacity: 0.5;
	            }
	        }
	        .quantity {
	            float: right;
	            line-height: 29px;
	            span {
	                float: left;
	                margin-top: 2px;
	            }
	            input {
	                margin-left: 10px;
	                border: 1px solid darken($color-gray-light, 5);
	                width: 40px;
	                float: right;
	                padding: 0px 5px;
	                box-sizing: border-box;
	                &:focus {
	                    border: 1px solid $color-primary-light;
	                    outline: none;
	                }
	            }
	        }
	    }
	}
	.choose_type {
	    width: 100%;
	    margin-top: 20px;
	    float: left;
	    .button {
	        width: 180px;
	        text-align: center;
	        left: 50%;
	        position: relative;
	        margin: 30px 0px 30px -90px;
	        cursor: pointer;
	    }
	    .item {
	        height: 145px;
	        margin-bottom: 20px;
	        .icon {
	            width: 100%;
	            height: 85px;
	            border: 1px solid $color-gray-light;
	            text-align: center;
	            line-height: 85px;
	            margin-bottom: 10px;
	        }
	        label {
	            font-size: 14px;
	            font-weight: $regular;
	            line-height: 21px;
	            width: 100%;
	            float: left;
	        }
	        input {
	            float: left;
	            margin: 7px 5px 0px 0px;
	            width: 15px;
	            &:focus {
	                outline: none;
	            }
	        }
	        span {
	            width: calc(100% - 25px);
	            float: left;
	            margin-top: 5px;
	        }
	    }
	}

	.product_info {
		h2 {
			margin-top: 10px;
		}

		p {
			font-size: 14px;
		}

		.point {
			width: 100%;
			float: left;
			margin-top: 10px;
			margin-bottom: 10px;

			&.long {
				h3 {
					margin-top: -3px;
				}
			}

			h3 {
				font-size: 16px;
				line-height: 24px;
				float: left;
				width: calc(100% - 50px);
				margin-left: 10px;
				margin-top: 10px;
				color: $color-text-gray;
				font-weight: $regular;
				font-size: 14px;
			}

			.icon {
				width: 40px;
				height: 40px;
				border-radius: 40px;
				float: left;
				color: #fff;
				text-align: center;
				line-height: 45px;
				font-size: 21px;
				background: $color-secondary;
				position: relative;

				.fa {
					float: left;
					margin: 9px 0px 0px 10px;
				}

				.line {
					position: absolute;
					bottom: -44px;
					margin-left: 17px;
					width: 5px;
					height: 45px;
					background: lighten($color-secondary, 20);
				}
			}

			.col-md-11 {
				padding-left:0px;
			}
		}
	}

	.checkout_slider {
		h3.sub-title {
			margin-top:30px;
			margin-bottom: 0px;
		}

		h4.sub-title {
			margin-top:30px;
			margin-bottom: 10px;
			font-size: 16px;
		}

		.field.small {
			margin-top: 0px;
		}
	}

	.other_pickup {
		display: none;
	}
	.files {
		input[type="file"] {
			width: 100%;
			float: left;
			font-size: 14px;
			line-height: 16px;
			//margin-top:10px;
			padding-bottom: 10px;
			border-bottom: 1px solid darken($color-gray-light, 5);

			&:focus {
				outline: none;
				border-bottom: 1px solid $color-primary;
			}
		}

		.row_field {
			padding-bottom: 10px;
			border-bottom: 1px solid darken($color-gray-light, 5);
		}
	}

	.add_file {
		float: left;
		text-align: left;
		width: 100%;
		margin-top: 10px;
		color: $color-primary;
		font-weight: bold;
		font-size: 14px;
		margin-bottom: 20px;
		cursor: pointer;

		.fa {
			float: left;
			font-size: 18px;
			margin: 3px 5px 0px 0px;
		}

		&:hover {
			color: darken($color-primary, 5);
		}
	}

}

.usp-bar.global {
	.logo {
		img {
			width: auto;
			height: 32px;
			float: right;
		}
	}
}
/*--------------------------------------------------------------
# Responsive page style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}