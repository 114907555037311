/*--------------------------------------------------------------
# places 
--------------------------------------------------------------*/

.places {
    width: 100%;
    float: left;
    padding: 100px 0px 125px 0px;
    background: $color-gray-light;

    p.center {
    	margin-bottom: 50px;
    }

    .item {
    	height: 250px;
    	border:1px solid $color-gray-light;
    	box-sizing:border-box;
    	text-align: center;
    	color: #fff;
    	position: relative;

    	.overlay {
    		width: 100%;
    		height: 100%;
    		float: left;
    		position: absolute;
    		background: rgba(0, 0, 0, 0.15);
    		left: 0px;
    		top: 0px;
    		transition:all 0.3s ease;

    		&:hover {
    			background: rgba(0, 0, 0, 0.05);
    		}
    	}

    	h3 {
    		color:#fff;
    		font-weight: bold;
    		margin-top:120px;
    		font-size: 36px;
    		line-height: 38px;
    		margin-bottom: 0px;
    		text-shadow:0px 0px 5px rgba(0, 0, 0, 0.7);
    	}

    	h4 {
    		color:#fff;
    		font-weight: bold;
    		margin-top:5px;
    		font-size: 21px;
    		text-shadow:0px 0px 5px rgba(0, 0, 0, 0.7);
    	}
    }
}


/*--------------------------------------------------------------
# Responsive reviews style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {
}

@include breakpoint(sm) {
	.places {
		padding-bottom: 0px;
	}
}
