/*--------------------------------------------------------------
# sidebar 
--------------------------------------------------------------*/

.sidebar {
    width: 100%;
    float: left;

    .box {
        box-shadow: 0 0 5px rgba(0,0,0,.05);
        border: 1px solid #f1f3f1;
        padding: 20px;
        float: left;
        margin-bottom: 20px;
        width: 100%;

        h3 {
            font-size: 21px;
            font-weight: $bold;
            margin: 0px 0px 15px 0px;
        }
    }

    .contact {
        text-align: center;
        color: $color-white;
        padding: 30px 20px;

        .button {
            width: 100%;
            float:left;
            margin-top: 20px;
        }
        
        h3 {
            color: $color-text;
            margin-bottom: 5px;
        }

        p {
            color: rgba(0, 0, 0, 0.8);
        }

        .phone {
            font-size: 18px;
            width: 100%;
            float: left;
            text-decoration: none;
            color: $color-text;
        }
    }

    .form {
        background:$color-primary;
        text-align: center;
        color: $color-white;
        
        span.wpcf7-list-item {
            margin:4px 0px;
            width: 100%;
            float: left;
        }

        label {
            width: 100%;
            float: left;
            text-align: left;
            line-height: 26px;
            color: #fff;

            span {
                width: calc(100% - 30px);
                float: right;
            }

            input {
                width: 20px;
                float: left;
            }
        }

        h3 {
            color:$color-white;
            margin:10px 0px 5px 0px;
        }

        p {
            font-size: 14px;
            text-align: center;
            color: rgba(255, 255, 255, 0.8);
        }

        input, textarea {
            width: 100%;
            float:left;
            margin-bottom: 5px;
            padding:7px 10px 5px 10px;
            box-sizing: border-box;
            color:$color-white;
            border: 1px solid darken($color-primary, 5);
            background:rgba(255, 255, 255, 0.2);

            &:focus {
                border: 1px solid darken($color-primary, 15);
                outline: none;
                background: rgba(255, 255, 255, 0.25);
            }
        }

        textarea {
            padding: 10px;
            height: 100px;
        }

        .wpcf7-not-valid-tip {
            display: none !important;
        }

        input.wpcf7-submit {
            width: 100%;
            margin-top: 10px;
        }

        .wpcf7-response-output {
            width: 100%;
            float: left;
            margin:10px 0px 0px 0px;
            padding: 10px;
            box-sizing: border-box;
            font-size: 14px;
            text-align: left;
            line-height: 21px;
        }

        .ajax-loader {
            display: none !important;
        }

        ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color:    rgba(255, 255, 255, 0.8);
        }
        :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
           color:    rgba(255, 255, 255, 0.8);
           opacity:  1;
        }
        ::-moz-placeholder { /* Mozilla Firefox 19+ */
           color:    rgba(255, 255, 255, 0.8);
           opacity:  1;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
           color:    rgba(255, 255, 255, 0.8);
        }
    }

    .usps {
        .usp {
            width: 100%;
            float: left;
            border-bottom: 1px solid #eee;
            margin-bottom: 20px;
            font-weight: bold;
            position: relative;
            padding-left: 40px;
            line-height: 21px;
            padding-bottom: 20px;

            .fa {
                font-size: 24px;
                float: left;
                margin-right: 10px;
                top: 50%;
                margin-top: -28px;
                left: 0px;
                width: 30px;
                height: 30px;
                background: #5fc217;
                text-align: center;
                line-height: 30px;
                border-radius: 30px;
                color: #fff;
                position: absolute;
            }
        }
    }
    
    .reviews {
        padding: 0px 0px;
        background: none;
        color: $color-text;
        float: left;
        width: 100%;

        .col-md-6, .col-md-3 {
            width: 100%;
            float:left;
            margin: 0px;
            padding: 0px;
            color: $color-text;
        }

        figure {
            width: 80px;
            height: 80px;
            float: none;
            margin: 10px auto 20px auto;
        }

        .col-md-6 {
            .description {
                font-size: 18px;
                line-height: 26px;
            }
        }

        .info {
            .author {
                color: rgba(0, 0, 0, 0.4);
            }
        }

        .container {
            width: 100%;
        }
    }
}


/*--------------------------------------------------------------
# Responsive sidebar style
--------------------------------------------------------------*/

@include breakpoint(lg) {}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}
